export default {
  users: {
    store: 'New User',
    list: 'Users'
  },
  sale: {
    store: 'New Sale',
    list: 'Sales'
  },
  return_sale: {
    store: 'Sale Refund',
    list: 'Sale Refunds'
  },
  purchase: {
    store: 'New Purchase',
    list: 'Purchases'
  },
  return_purchase: {
    store: 'Purchase Refund',
    list: 'Purchase Refunds'
  },
  product: {
    store: 'New Product',
    list: 'Products'
  },
  role: {
    list: 'User Group'
  },
  receive: {
    store: 'New Receive',
    list: 'Receives'
  },
  payment: {
    store: 'New Payment',
    list: 'Payments'
  },
  internal_fund: {
    store: 'New Internal Transfer',
    list: 'Internal Transfer'
  },
  cost: {
    store: 'New Cost',
    list: 'Costs'
  },
  bank: {
    store: 'New Bank',
    list: 'Banks'
  },
  cash: {
    store: 'New Cash',
    list: 'Cashes'
  },
  payment_gateway: {
    store: 'New Payment Gateway',
    list: 'Payment Gateways'
  },
  service: {
    store: 'New Service',
    list: 'Services'
  },
  storeroom_receipt: {
    store: 'New Warehouse Receipt',
    list: 'Warehouse Receipts'
  },
  storeroom: {
    store: 'New Warehouse',
    list: 'Warehouses'
  },
  storeroom_deficit: {
    store: 'New Deficit & Surplus',
    list: 'Deficit & Surplus'
  },
  storeroom_conversion: {
    store: 'New Conversion',
    list: 'Conversions'
  },
}
