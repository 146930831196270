export default {
  title: 'Settings',

  importantData: {
    balance: 'inventory',
    employeeCount: 'Number of employees'
  },

  buttons: {
    save: 'save changes',
    reset: 'undo changes',
    removeLogo: 'remove logo',
    uploadLogo: 'Load Logo'
  },

  companyInfo: {
    title: 'Company Info',

    labels: {
      generalInfo: 'General info',
      telInfo: 'Tell info',
      financialInfo: 'Financial info',
      uploadLogo: 'Upload logo',
      preview: 'Preview:',
      name: 'Company name',
      managerName: 'Manager full name',
      country: 'Country',
      province: 'State',
      city: 'City',
      address: 'Address',
      phoneNumber: 'Phone number',
      whatsAppSupport: 'WhatsApp support',
      managerPhoneNumber: 'Manager phone number'
    },

    description: {
      logoRule: 'Authorized JPG, JPEG or PNG formats. Maximum logo size 800 KB ',
      generalInfo: 'This box displays the general information of the company.',
      telInfo: 'In this box the company contact information is displayed.',
      financialInfo: 'This box displays the companys financial information.'
    },

    validators: {
      imageSize: 'The selected file size is too large',
      fileFormat: 'The selected file format is not allowed',
      name: 'Name is not valid'
    },

    notifications: {
      logo: {
        upload: {
          success: 'Logo uploaded successfully'
        },
        delete: {
          success: 'Logo removed successfully'
        }
      },

      edit: {
        success: 'Store information updated successfully'
      }
    }
  },

  accessGroup: {
    title: 'User Groups',

    notifications: {
      insert: {
        success: 'User group added successfully',
        error: 'Add user group failed'
      },

      edit: {
        success: 'User group successfully edited',
        error: 'User group editing encountered an error'
      },

      delete: {
        success: 'User group successfully deleted',
        error: 'The user group could not be found.',
        cantDelete: 'The user group could not be deleted because it has a subgroup.'
      },

      deleteUser: {
        success: 'User successfully deleted from group',
        error: 'Delete user from group has error'
      }
    },

    confirmations: {
      delete: {
        title: 'Delete User Group',
        body: 'Are you sure you want to delete {name}?'
      },

      deleteUser: {
        title: 'Delete User From User Group',
        body: 'Are you sure you want to delete {name} from this group?'
      }
    },

    insert: {
      title: 'Add a new user group',

      labels: {
        name: 'User Group Title',
        allowedActiveSession: 'Active sessions allowed',
        access: 'access',
        insert: 'Add',
        inMenu: 'Menu',

        accessList: {
          manager: 'General Manager Access',

          users: {
            totalAccess: 'Full access of persons',
            show: 'View People',
            insert: 'Add person',
            update: 'full edit',
            delete: 'Temporarily delete person',
            setRole: 'Assign a role to a person'
          },

          roles: 'Full access to roles',
          treasury: {
            title: 'Full access to the Treasury',

            bank: 'full access to banks',
            cash: 'Full access to funds'
          },

          reports: {
            title: 'Full access to reports',
            logs: 'access logs'
          },

          delete: {
            title: 'Permanent removal',

            user: 'People',
            banks: 'Banks',
            cash: 'Cashiers'
          }
        }
      },

      validators: {
        name: 'Group name is not entered correctly',
        nameCantEmpty: 'Name could not be empty',
        activeSessionCount: 'Active sessions should be less than equal 5',
        permissions: 'No access selected'
      }
    },

    edit: {
      title: 'Edit User Group',

      validators: {
        name: 'Group name is not entered correctly',
        nameCantEmpty: 'Name could not be empty',
        activeSessionCount: 'Active sessions should be less than equal 5',
        permissions: 'No access selected'
      }
    },

    insertUserToAccessGroup: {

      notifications: {
        success: 'access group successfully set to user',
        error: 'set access group to user failed',
        noUserSelected: 'no user selected'
      }
    },

    table: {

      header: {
        row: 'Row',
        name: 'Access Title'
      }
    },

    actions: {
      insert: 'Add',
      save: 'Register'
    }
  },

  users: {
    title: 'People Settings',

    labels: {
      gender: 'gender mandatory'
    }
  },

  security: {
    title: 'Security',

    labels: {
      disActive: 'Compulsory exit period if not active',
      minute: 'minute'
    }
  },

  customize: {
    title: 'System Personalization',

    labels: {
      theme: 'Select the desired theme'
    }
  },

  financial: {
    title: 'Financial',

    rule: 'Rules',
    tax: 'Vat',
    paymentInfo: 'Payment info',
    NegativeValue: 'Bank and fund can be negative',

    labels: {
      purchaseTax: 'Purchase vat percent',
      saleTax: 'Sale vat percent',
      vat: 'Vat tax percent',
      taxRegistrationNumber: 'Tax Registration Number',
      bankName: 'Bank name',
      accountName: 'Account name',
      accountNumber: 'Account number',
      iban: 'IBAN',
      swiftCode: 'Swift code',
      cardNumber: 'Card number'
    },

    notifications: {
      negativeBalanceError: 'Due to the negative balance of a number of banks or funds, the desired settings has error',
      wrongValues: 'The entered information is not valid',

      edit: {
        success: 'Financial information has been updated successfully',
        error: 'Update financial information has error'
      }
    }
  },

  product: {
    title: 'Product',

    labels: {
      addAttributeManually: 'Enable add attribute to product manually'
    },

    notifications: {
      edit: {
        success: 'Product information has been updated successfully',
        error: 'Update product information has error'
      }
    }
  },

  tax: {
    title: 'Vat',

    labels: {
      enableTax: 'Enable vat',
      purchaseTax: 'Purchase vat percent',
      saleTax: 'Sale vat percent',
      taxRegistrationNumber: 'Tax Registration Number',
      enableShippingTax: 'Shipping price includes vat'
    },

    notifications: {
      edit: {
        success: 'Vat information has been updated successfully',
        error: 'Update vat information has error'
      }
    }
  },

  shipping: {
    title: 'Shipping',

    labels: {
      cost: 'Shipping cost'
    },

    notifications: {
      wrongValues: 'The entered information is not valid',

      edit: {
        success: 'Shipping information has been updated successfully',
        error: 'Update shipping information has error'
      }
    }
  },

  print: {
    title: 'Print',

    labels: {
      companyName: 'Company name',
      lightLogo: 'Light logo',
      stamp: 'Stamp',
      QR: 'QR',
      theme: 'Postal label template',
      companyInfoWithZeroVat: 'If vat is zero, company info show in print invoice'
    },

    themeOptions: {
      theme1: 'A4 Portrait',
      theme2: 'A5 Portrait'
    },

    notifications: {
      wrongValues: 'The entered information is not valid',

      edit: {
        success: 'Print information has been updated successfully',
        error: 'Update Print information has error'
      }
    }
  },

  financialYear: {
    title: 'Financial year',

    statusTypes: {
      all: 'All',
      active: 'Active',
      disActive: 'inactive'
    },

    table: {
      header: {
        rowNumber: 'Row',
        name: 'Name',
        startDate: 'Start date',
        endDate: 'End date',
        status: 'Status',
        show: 'Show'
      }
    },

    notifications: {
      isActived: 'The desired financial year has already been activated',

      insert: {
        success: 'The new financial year was successfully registered',
        error: 'Register financial year has error'
      },

      edit: {
        success: 'Financial year was successfully edited',
        error: 'Edit financial year has error'
      },

      switch: {
        success: 'Financial year successfully switched',
        error: 'Switch financial year has error'
      },

      parseError: {
        period: 'The selected fiscal year time range is not valid',
        paymentGateways: 'The balance of a number of payment gateways has not been announced!',
        storeroom: 'A number of warehouse pull in have not been confirmed!',
        periodNotEnded: 'The current financial year has not yet ended!'
      }
    },

    confirmations: {
      insert: {
        title: 'Insert financial year confirmation',
        body: 'Are you sure about creating a new financial year?'
      },

      show: {
        title: 'View financial year confirmation',
        body: 'Are you sure you want view {name} fiscal year?'
      }
    },

    edit: {
      title: 'Financial year configuration',

      periodTypes: {
        3: '3 months',
        6: '6 months',
        12: '1 year'
      },

      labels: {
        name: 'Name',
        startDate: 'Start Date',
        endDate: 'End Date',
        period: 'Financial year time range'
      }
    }
  },

  message: {
    title: 'SMS panel',

    labels: {
      provider: 'Provider',
      farazsms: 'Fraz SMS',
      smsglobal: 'SMS Global',
      farazsmsUsername: 'Fraz SMS Username',
      farazsmsPassword: 'Fraz SMS Password',
      farazsmsSender: 'Fraz SMS Sender',
      smsglobalUsername: 'SMS Global Username',
      smsglobalPassword: 'SMS Global Password',
      smsglobalSender: 'SMS Global Sender',
      messangerKey: 'Authentication code',
      templates: 'Templates',
      login: 'Login SMS template',
      signup: 'Register SMS template',
      confirmInfo: 'Verify SMS template'
    },

    notifications: {
      edit: {
        success: 'The SMS panel information of the company has been successfully updated'
      }
    },

    invalidTexts: {
      onlyEnglish: 'Only English characters are allowed!'
    }
  },

  General: {
    title: 'General',
    logo: {
      rule: 'Authorized JPG, JPEG or PNG formats. Maximum logo size 800 KB '
    },
    club: {
      code: 'company code',
      name: 'company name',
      managerName: 'managers first and last name',
      address: 'Address',
      phoneNumber: 'Mobile Number',
      managerPhoneNumber: 'CEO Mobile Number'
    }
  },

  Treasury: {
    title: 'Treasury',
    rule: 'Rules',
    NegativeValue: 'Banks and funds can be negative',

    notifications: {
      negativeBalanceError: 'Due to the negative balance of a number of banks or funds, the desired settings were saved with an error',

      edit: {
        success: 'Treasury information has been updated successfully',
        error: 'Update treasury information has error'
      }
    }
  },

  Locale: {
    title: 'Languages',
    language: 'Language',
    basicSetting: 'Basic settings',

    labels: {
      basicSetting: 'Base setting',
      defaultLanguage: 'Club default language',
      defaultCurrency: 'Club default currency',
      region: 'Club default region',
      defaultCountryCode: 'Club default country code'
    },

    currency: {
      IRT: 'IRT',
      IRR: 'IRR',
      USD: 'USD',
      AED: 'AED',
      other: 'other'
    },

    notifications: {
      edit: {
        success: 'Locale setting was successfully edited',
        error: 'Edit locale setting has error'
      }
    }
  },

  warehouse: {
    title: 'Warehousing',

    labels: {
      checkExitReceiptByRow: 'Pull out confirmation by row'
    },

    notifications: {
      edit: {
        success: 'Edit inventory setting successfully done.',
        error: 'Edit inventory setting has error.'
      },
      parseError: {
        defaultStoreroomForPersonalSale: 'Selected default warehouse is not valid!'
      }
    }
  },

  purchase: {
    title: 'Purchase',

    labels: {
      defaultStoreroom: 'Default warehouse for purchase',
      hasPrePurchaseInvoiceAge: 'Pre-purchase invoice deleted after special time',
      prePurchaseInvoiceAge: 'Pre-purchase invoice deleted after ... days.',
      hasPreReturnPurchaseInvoiceAge: 'Pre-purchase return invoice deleted after special time',
      preReturnSaleInvoiceAge: 'Pre-purchase return invoice deleted after ... days.'
    },

    notifications: {
      edit: {
        success: 'Edit purchase setting successfully done',
        error: 'Edit purchase setting has error'
      },

      parseError: {
        prePurchaseInvoiceAge: 'Number of days for delete pre-purchase invoice is incorrect',
        preReturnSaleInvoiceAge: 'Number of days for delete pre-purchase return invoice is incorrect'
      }
    }
  },

  sale: {
    title: 'Sale',

    labels: {
      score: 'point',
      defaultStoreroom: 'Default warehouse for personal sale',
      hasPreSaleInvoiceAge: 'Pre-sale invoice deleted after special time',
      preSaleInvoiceAge: 'Pre-sale invoice deleted after ... days.',
      hasPreReturnSaleInvoiceAge: 'Pre-sale return invoice deleted after special time',
      preReturnSaleInvoiceAge: 'Pre-sale return invoice deleted after ... days.',
      profitConversationRate: 'Profit conversation rate',
      enableRetailShipping: 'Retail sale has shipping',
    },

    notifications: {
      edit: {
        success: 'Edit sale setting successfully done',
        error: 'Edit sale setting has error'
      },

      parseError: {
        preSaleInvoiceAge: 'Number of days for delete pre-sale invoice is incorrect',
        preReturnSaleInvoiceAge: 'Number of days for delete pre-sale return invoice is incorrect'
      }
    }
  },

  reset: {
    title: 'Reset',

    counts: {
      costs: '"{count}" costs exist',
      documents: '"{count}" documents exist',
      financial_years: '"{count}" financial years exist',
      internal_funds: '"{count}" internal funds exist',
      invoices: '"{count}" invoices exist',
      opening_inventories: '"{count}" opening inventories exist',
      payments: '"{count}" payments exist',
      receives: '"{count}" receives exist',
      storeroom_receipts: '"{count}" warehouse pull in exist'
    },

    labels: {
      companyName: 'Name',
      confirm: 'Confirm, reset app',
      codeValidate: 'Code validity ',
      second: 'Second',
      resendCode: 'Resend Code',
      storedData: 'You are about to reset app containing:',
      resetConfirm: 'Are you absolutely sure?',
      enterConfirm: 'Enter the following to confirm:'
    },

    notifications: {
      otpExpired: 'Code expired',
      otpWrong: 'Code is wrong',

      sendOtp: {
        success: 'The verification code of reset app was sms to your phone number.',
        error: 'Send verification code of reset app has error',
        unSaveChanges: 'There are some unsaved changes in app'
      },
      confirmReset: {
        success: 'Reset app successFully done',
        error: 'Reset app has error'
      }
    },

    confirmations: {
      reset: {
        title: 'Reset app',
        body: 'Are you sure about reset app?'
      }
    }
  }
}
