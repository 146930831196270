import en from './languages/en'
import fa from './languages/fa'
import ar from './languages/ar'

export default {
  en,

  fa,

  ar
}
