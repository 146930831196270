export default {
  // navMenuItems Words
  dashboard: 'لوحة القيادة',


  beginning: 'بداية',
  beginningUsers: ' بداية المستخدمين',
  beginningCashs: 'بداية النقدية',
  beginningBanks: 'بداية البنوك',
  beginningWarehouse: 'المستودع الأولي',

  purchasesInvoice: 'فاتورة الشراء',

  warehousing: 'المستودعات',
  inventoryValuation: 'تصفح المستودع المبلغ',

  banks: 'بنك',
  cash: 'النقدية',
  transaction: 'المعاملات',
  transference: 'تحويل الأموال الداخلي',
  accountingTable: 'جدول الحسابات',
  income: 'دخل',
  cost: 'كلفة',

  accounting: 'محاسبة',

  save: 'سجل',
  cancel: 'إلغاء',

  logout: 'مخرج',

  // alert title
  alert: {
    duplicateRequest: {
      title: 'تحذير',
      message: 'طلبك السابق قيد المعالجة ، يرجى الانتظار'
    },
    error: {
      title: 'خطأ'
    },
    warning: {
      title: 'تحذير',
      repeatedRequestText: 'طلبك السابق قيد المعالجة ، يرجى الانتظار'
    },
    message: {
      title: 'رسالة',
      pleaseWait: 'تم ارسال الطلب. أرجو الإنتظار...'
    }
  },

  // navbar actions
  navbar: {
    back: 'العودة',
    save: 'متجر',

    notification: {
      newMessage: 'رسالة جديدة',
      showAll: 'اعرض كل الرسائل'
    }
  },

  // table statics
  draggableTable: {

    filter: {

      types: {
        search: 'كن ضمن ذلك',
        equals: 'يكون مساويا',
        notEqual: 'لا يساوي',
        lessThanEquals: 'أقل من يساوي',
        greaterThanEquals: 'أكبر يساوي'
      }
    }
  },

  // login page
  login: {
    title: 'تسجيل الدخول',

    getPhoneNumberStepMessage1: 'الحصول على رسالة خطوة رقم الهاتف ',
    getPhoneNumberStepMessage2: '(كود من خمسة أرقام)',
    getPhoneNumberStepMessage3: ' سيتم إرسالها لتأكيد رقمك.',
    phoneNumber: 'رقم الهاتف المحمول',
    phoneNumberIsNotValid: 'لم يتم إدخال رقم الهاتف المحمول بشكل صحيح',
    phoneNumberIsNull: 'لم يتم إدخال رقم الهاتف المحمول',

    otpCodeSentStepMessage1: 'لرقم الهاتف ',
    otpCodeSentStepMessage2: ' تم إرسال رمز مكون من 5 أرقام. الرجاء إدخال الرمز',
    otpCodeIsNotValid: 'لم يتم إدخال رمز التحقق بشكل صحيح',
    otpCodeIsNull: 'لم يتم إدخال رمز التحقق',
    retrieveTheCode: 'استرجع الكود',

    loginToPanel: 'تسجيل الدخول إلى لوحة {name}',
    resendOTPMessage: 'اختر إحدى الطرق التالية للحصول على الرمز',
    otpCall: 'سيتم إجراء مكالمة على {number} بعد لحظات قليلة',
    resendOTPTypes: {
      sms: 'رسالة قصيرة',
      tel: 'اتصل'
    },

    otp: 'كلمة السر لمرة واحدة',
    otpSend: 'تم إرسال رمز التحقق إلى الرقم {number}',

    getSuperAdminTokenStepMessage: 'الرجاء إدخال الرمز الخاص بك.',
    token: 'رمز مدير النظام',

    buttons: {
      getOTPCode: 'احصل على الكود',
      login: 'تسجيل الدخول'
    },

    notification: {
      login: {
        title: 'رسالة',
        message: 'لقد قمت بتسجيل الدخول بنجاح'
      },

      logout: {
        title: 'رسالة',
        message: 'لقد قمت بتسجيل الخروج بنجاح'
      },

      forceLogout: {
        title: 'رسالة',
        message: 'يرجى تسجيل الدخول مرة أخرى بسبب عدم استخدام النظام لفترة طويلة'
      }
    }

  },

  // profile page
  profile: {
    title: 'الملف الشخصي',

    dynamicTitle: 'الملف الشخصي {name}',

    details: {
      dynamicCompany: 'شركة {company}'
    },

    importantData: {
      balance: 'محفظة نقود',
      score: 'نتيجة',
      character: 'مجموعة المستخدمين'
    },

    actions: {
      transactions: 'المعاملات',
      addresses: 'عناوين',
      receivedEvents: 'الرسائل الواردة',
      sentEvents: 'الرسائل المرسلة'
    },

    notifications: {
      edit: {
        success: 'تم تحديث المعلومات بنجاح',
        error: 'تحديث المعلومات كان مصحوبا بخطأ'
      },

      parseError: {
        required: 'لم يتم إدخال القيم المطلوبة'
      }
    },

    edit: {
      title: 'تحرير معلومات ملف التعريف',

      labels: {
        gender: 'بين الجنسين',
        base: 'القاعدة',

        company: {
          name: 'اسم الشركة',
          ownerName: 'اسم الشخص المسؤول عن الشراء',
          phoneNumber: 'رقم الاتصال للمشتري',
          email: 'البريد الإلكتروني للشركة'
        },

        user: {
          name: 'اسم',
          family: 'الكنية',
          phoneNumber: 'رقم التليفون',
          email: 'البريد الإلكتروني'
        }
      },

      validators: {
        name: 'لم يتم إدخال الاسم بشكل صحيح',
        family: 'لم يتم إدخال الاسم الأخير بشكل صحيح',
        companyName: 'لا يمكن أن يكون اسم الشركة فارغًا',
        phoneNumber: 'تنسيق رقم الاتصال الخاطئ',
        email: 'تنسيق البريد الإلكتروني غير صحيح'
      }
    },

    address: {
      title: 'عناوين',

      labels: {
        name: 'عنوان اسم',
        province: 'حالة',
        city: 'مدينة',
        address: 'العنوان البريدي',
        plaque: 'البلاك',
        postalCode: 'الكود البريدى',
        delete: 'حذف',
        save: 'سجل',

        receiver: {
          info: 'معلومات المستلم',
          name: 'اسم المستلم',
          family: 'لقب المستلم',
          phoneNumber: 'هوية المتصل'
        }
      },

      description: {
        noAddress: 'لا يوجد عنوان مسجل!'
      },

      validators: {
        city: 'لا يمكن اختيار المدينة',
        province: 'لا يمكن تحديد المقاطعة',
        name: 'لم يتم إدخال الاسم بشكل صحيح',
        nameCantEmpty: 'لا يمكن أن يكون الاسم فارغًا',
        address: 'لم يتم إدخال العنوان بشكل صحيح',
        addressCantEmpty: 'لا يمكن أن يكون العنوان فارغًا',
        plaque: 'تنسيق لوحة الترخيص غير صحيح',
        postalCode: 'تنسيق الرمز البريدي غير صحيح',
        phoneNumber: 'تنسيق رقم الاتصال الخاطئ',
        receiver: {
          name: 'لم يتم إدخال الاسم بشكل صحيح',
          nameCantEmpty: 'لا يمكن أن يكون اسم المستلم فارغًا',
          family: 'لم يتم إدخال الاسم الأخير بشكل صحيح',
          familyCantEmpty: 'لا يمكن ترك اسم لقب المستلم فارغًا',
          phoneNumber: 'لا يمكن ترك حقل معرف المتصل فارغًا'
        }
      },

      notifications: {
        insert: {
          success: 'تم إنشاء العنوان الجديد بنجاح',
          error: 'إضافة عنوان جديد رافقه خطأ'
        },
        edit: {
          success: 'تم تحرير العنوان بنجاح',
          error: 'كان تحرير العنوان مصحوبًا بخطأ'
        },
        delete: {
          success: 'تم حذف عنوان URL بنجاح.',
          error: 'كان حذف العنوان مصحوبًا بخطأ'
        }
      },

      insert: {
        title: 'أضف عنوان جديد'
      },

      edit: {
        title: 'تعديل العنوان'
      }
    },

    events: {
      seenTypes: {
        all: 'الجميع',
        seen: 'لاحظ',
        unSeen: 'لم أر'
      },

      list: {
        table: {
          header: {
            rowNumber: 'صف',
            creator: 'مرسل',
            receiver: 'المتلقي',
            subject: 'مشكلة',
            content: 'محتوى',
            seen: 'عرض الحالة',
            type: 'اكتب',
            priority: 'أفضلية',
            createdAt: 'تاريخ الإرسال'
          }
        }
      },

      receive: {
        title: 'الرسائل الواردة'
      },

      send: {
        title: 'تم إرسال الرسائل'
      }
    },

    transactions: {
      title: 'دوران',

      table: {
        header: {
          docNumber: 'رقم الوثيقة',
          date: 'تاريخ',
          documentation: 'توثيق',
          price: 'مقدار',
          balance: 'جرد'
        }
      }
    },

    buttons: {
      save: 'احفظ التغييرات',
      reset: 'التراجع عن التغييرات',
      removeAvatar: 'حذف الصورة',
      uploadAvatar: 'تحميل الصور'
    },

    General: {
      title: 'عام',
      avatar: {
        rule: 'تنسيقات JPG أو JPEG أو PNG المعتمدة. أقصى حجم للصورة 800 كيلو بايت'
      },
      user: {
        name: 'اسم',
        lastName: 'الكنية',
        role: 'مسؤولية',
        phoneNumber: 'رقم التليفون',
        address: 'تبوك',
        maxDebt: 'الحد الأقصى لمبلغ الدين',
        balance: 'معدل الصلاحية'
      }
    },

    MyFinancialReports: {
      title: 'البيانات المالية الخاصة بي'
    }
  },

  smsPanel: 'لوحة الرسائل القصيرة',

  // opening inventories
  opening: {
    users: {
      title: 'اول دوره اشخاص'

    },

    cashBoxes: {
      title: 'الفترة الأولى للصناديق'

    },

    banks: {
      title: 'الفترة الأولى للبنوك'

    },

    stores: {
      title: 'الفترة الأولى من المستودعات'

    }
  },

  // companies
  companies: {
    title: 'قائمة الشركات',

    table: {
      header: {
        row: 'صف',
        code: 'الشفرة',
        name: 'اسم الشركة',
        equity: 'الأموال',
        totalDebt: 'الدين الكلي',
        totalAsset: 'إجمالي الأصول'
      }
    },


    labels: {
      generalInfo: 'معلومات عامة عن الشركة',
      telInfo: 'معلومات الاتصال بالشركة',
      financialInfo: 'معلومات مالية',
      uploadLogo: 'قم بتحميل الشعار',
      preview: 'معاينة:',
      code: 'كود الشركة',
      name: 'اسم الشركة',
      managerName: 'اسم المدير',
      address: 'عنوان',
      phoneNumber: 'رقم التليفون',
      managerPhoneNumber: 'رقم الاتصال بالمدير',
      financialFrom: 'بداية السنة المالية',
      financialTo: 'نهاية السنة المالية'
    },

    description: {
      logoRule: 'تنسيقات JPG أو JPEG أو PNG المعتمدة. الحد الأقصى لحجم الشعار 800 كيلو بايت',
      generalInfo: 'في هذا المربع ، يتم عرض المعلومات العامة للشركة.',
      telInfo: 'في هذا المربع ، يتم عرض معلومات الاتصال بالشركة.',
      financialInfo: 'يعرض هذا المربع المعلومات المالية للشركة.'
    },

    buttons: {
      save: 'احفظ التغييرات',
      reset: 'التراجع عن التغييرات',
      removeLogo: 'قم بإزالة الشعار',
      uploadLogo: 'قم بتحميل الشعار'
    },

    validators: {
      imageSize: 'حجم الملف المحدد كبير جدًا',
      fileFormat: 'تنسيق الملف المحدد غير مسموح به'
    },

    notifications: {
      logo: {
        upload: {
          success: 'تم تحميل الشعار بنجاح'
        },
        delete: {
          success: 'تمت إزالة الشعار بنجاح'
        }
      },

      insert: {
        success: 'تمت إضافة معلومات المتجر بنجاح'
      },

      edit: {
        success: 'تم تحديث معلومات المتجر بنجاح'
      }
    },

    company: {
      title: 'شركة'
    },

    insert: {
      title: 'أضف شركة'
    },

    edit: {
      title: 'تحرير معلومات الشركة'
    }
  },

  // backup page
  backup: {
    title: 'دعم',

    status: {
      all: 'الجميع',
      saving: 'إنقاذ',
      saved: 'أنقذ',
      retrieved: 'تم الاسترجاع'
    },

    notifications: {
      insert: {
        success: 'تم إنشاء ملف النسخ الاحتياطي بنجاح',
        error: 'خطأ في إنشاء ملف النسخ الاحتياطي'
      },

      recovery: {
        success: 'تم إرسال الطلب بنجاح',
        error: 'هذه النسخة الاحتياطية غير قابلة للاسترداد',
        cantSend: 'أرسل الطلب خطأ'
      }
    },

    confirmations: {
      insert: {
        title: 'أصنع نسخة إحتياطية',
        body: 'هل أنت متأكد أنك تريد عمل نسخة احتياطية؟'
      },

      recovery: {
        title: 'أصنع نسخة إحتياطية',
        body: 'هل أنت متأكد أنك تريد عمل نسخة احتياطية؟'
      }
    },

    list: {
      table: {
        header: {
          rowNumber: 'صف',
          date: 'تاريخ',
          status: 'شرط',
          recovery: 'استعادة'
        }
      }
    }
  },

  // plugins page
  plugins: {
    title: 'الإضافات',


    wooreceiver: {
      title: 'موصل إلى ووکامرس',

      shopStatus: {
        disActive: 'غير نشط',
        checkInformation: 'تحقق من المعلومات',
        stopped: 'تم إيقافه',
        active: 'نشيط',
        receivingInformation: 'تلقي المعلومات'
      },

      labels: {
        name: 'اسم المتجر',
        url: 'عنوان المتجر',
        key: 'مفتاح مصادقة الواجهة',
        secret: 'كلمة مرور مصادقة الواجهة',
        receive: 'تلقى',
        userInfo: 'معلومات المستخدم',
        productInfo: 'معلومات المنتج',
        orderInfo: 'معلومات الطلب',
        delete: 'حذف المتجر'
      },

      description: {
        notExist: 'لا توجد متاجر مسجلة!'
      },

      notifications: {
        receivingInformation: 'موصل المتجر المطلوب يتلقى المعلومات. أرجو الإنتظار...',
        noChange: 'تم إجراء أية تغييرات',
        requiredFields: 'لم يتم إدخال القيم المطلوبة',
        requestSend: 'تم تقديم الطلب بنجاح',
        serviceNotActive: 'الخدمة المعنية غير نشطة',

        insert: {
          success: 'تم تسجيل معلومات المتجر الجديد بنجاح',
          error: 'الخدمة المعنية غير نشطة'
        },

        edit: {
          success: 'تم تحرير معلومات المتجر بنجاح',
          error: 'الخدمة المعنية غير نشطة'
        },

        delete: {
          success: 'تم حذف معلومات المتجر بنجاح',
          error: 'الخدمة المعنية غير نشطة'
        }
      },

      confirmations: {
        delete: {
          title: 'تأكيد حذف المتجر',
          body: 'هل أنت متأكد أنك تريد حذف {name} مو مؤقتًا؟'
        }
      },

      profile: {
        title: 'الملف الشخصي للموقع',
        dynamicTitle: 'المتجر {name}',

        importantData: {
          url: 'عنوان',
          token: 'رمز',
          status: 'شرط'
        },

        actions: {
          category: 'قائمة الفئات',
          categoryMapper: 'فئة مخطط',
          attributes: 'ميزات التحكم',
          products: 'مراقبة البضائع',
          inventory: 'مراقبة المخزون',
          price: 'التحكم في السعر',
          paymentGateways: 'التحكم في طرق الدفع',
          orders: 'مراقبة الفاتورة',
          users: 'تحكم العملاء',
          setting: 'إعدادات'
        },

        notifications: {
          attributeErrorNotSolved: 'لم يتم إصلاح أخطاء معلومات السمة'
        }
      },

      insert: {
        title: 'إضافة متجر جديد'
      },

      edit: {
        title: 'تحرير المتجر',
        dynamicTitle: 'تحرير المتجر {name}'
      },

      attributes: {
        list: {
          title: 'ميزات التحكم',

          table: {
            header: {
              rowNumber: 'صف',
              name: 'اسم الميزة',
              values: 'قيم السمات',
              send: 'إرسال'
            }
          },

          confirmations: {
            get: {
              title: 'هل أنت متأكد أنك تريد الحصول على قائمة الميزات؟'
            },
            sync: {
              title: 'هل أنت متأكد من أنك تريد نقل قائمة الميزات إلى المستخدم؟'
            }
          },

          notifications: {
            handleGet: {
              error: 'تم استلام معلومات عن جميع الميزات'
            },
            handleSync: {
              error: 'تم نقل المعلومات المتعلقة بجميع الميزات إلى المستخدم'
            },
            receive: {
              success: 'تم إرسال الطلب بنجاح',
              error: 'رافق إرسال الطلب خطأ'
            },
            sync: {
              success: 'تم إرسال الطلب بنجاح',
              error: 'رافق إرسال الطلب خطأ'
            },
            send: {
              success: 'تم إرسال الطلب بنجاح',
              error: 'رافق إرسال الطلب خطأ'
            }
          }
        },

        insert: {
          title: 'أضف ميزة جديدة'
        },

        edit: {
          title: 'تحرير الميزة'
        },

        profile: {
          title: 'خاصية',
          dynamicTitle: 'خاصية {name}'
        },

        logs: {
          title: 'سجلات الميزات',
          dynamicTitle: 'سجلات الميزات {name}',

          labels: {
            values: 'قيم السمات',
            destinations: 'وصف السجل'
          },

          table: {
            header: {
              rowNumber: 'صف',
              description: 'وصف',
              date: 'تاریخ'
            }
          }
        }
      },

      categories: {
        title: 'مراقبة الفئات',

        labels: {
          withoutCategory: 'لا تصنيف'
        },

        notifications: {
          notCategorySelected: 'لم يتم تحديد فئات',

          setMainCategories: {
            success: 'تم تسجيل الفئات الرئيسية بنجاح',
            error: 'تم تسجيل الفئات الرئيسية مع وجود خطأ'
          },

          setSetting: {
            success: 'تم تسجيل إعدادات معين الفئة بنجاح',
            error: 'حدث خطأ أثناء تسجيل إعدادات معين الفئات'
          }
        },

        categoryMapper: {
          title: 'فئات مصمم الخرائط',

          table: {
            header: {
              rowNumber: 'صف',
              name: 'عنوان فئة ووکامرس',
              mapTo: 'عنوان فئة یوزرال'
            }
          }
        }
      },

      products: {
        title: 'مراقبة المنتج',

        types: {
          all: 'الجميع',
          simple: 'سلع بسيطة',
          variant: 'البضائع المتغيرة',
          service: 'خدمة'
        },

        dataCategory: {
          generalInfo: 'معلومات عامة',
          attributes: 'صفات',
          inventory: 'ادارة المخزون',
          variants: 'المتغيرات'
        },

        productType: {
          label: 'نوع المنتج',
          simple: 'بسيط',
          variant: 'عامل'
        },

        labels: {
          useral: 'یوزرال',
          woocommerce: 'ووکامرس',
          active: 'نشيط',
          disActive: 'غير نشط',
          have: 'حصل عليها',
          notHave: 'لا يمتلك',
          from: 'من',
          to: 'حتى ال',
          withoutPrice: 'لا ثمن',
          code: 'هوية شخصية',
          barcode: 'الباركود',
          description: 'وصف',
          inventory: 'جرد',
          chooseAttribute: 'اختيار ميزة',
          chooseCategory: 'اختر تصنيف',
          withoutCategory: 'لا تصنيف',
          add: 'أضف البضائع',
          delete: 'إزالة البضائع',

          product: {
            name: 'اسم المنتج',
            category: 'التجمع',
            price: 'أسعار السلع'
          },
          service: {
            name: 'اسم الخدمة',
            category: 'فئة الخدمة',
            price: 'سعر الخدمة'
          }
        },

        table: {
          header: {
            delete: 'حذف',
            price: 'السعر',
            barcode: 'الباركود',
            stock: 'جرد'
          }
        },

        descriptions: {
          title: 'وصف',

          woocommerceDescription: 'يتم تسجيل معلومات المنتج مباشرة في متجرك قيد المراجعة.',
          attribute: 'يمكنك تحديد الميزة المطلوبة وإضافتها إلى قائمة ميزات المنتج. إذا كان المنتج متغيرًا ، فيمكنك تحديد <b> واحد </b> من الميزات كميزة متغيرة للمنتج',
          inventory: 'في هذه الصفحة يمكنك إدخال مخزون المنتج بالكامل. إذا كان رقم المخزون صفرًا ، فسيتم اعتبار المنتج غير موجود.',
          noAttributeChooseForVariables: 'لم يتم تحديد سمة كمتغير'
        },

        confirmations: {
          sync: {
            title: '',
            body: 'هل أنت متأكد أنك تريد نقل سلع الموقع إلى المستخدم؟'
          },
          get: {
            title: '',
            body: 'هل أنت متأكد أنك تريد الحصول على قائمة السلع؟'
          },
          delete: {
            title: 'تأكيد',
            body: 'هل أنت متأكد أنك تريد حذف هذا البند؟ إذا تم التأكيد ، ستتم أيضًا إزالة معلومات المنتج من متجرك!'
          }
        },

        validators: {
          imageSize: 'حجم الملف المحدد كبير جدًا',
          fileFormat: 'تنسيق الملف المحدد غير مسموح به'
        },

        notifications: {
          requestSend: 'تم ارسال الطلب. أرجو الإنتظار...',
          technicalProblem: 'في القائمة ، هناك سلع بها مشاكل فنية ، يرجى محاولة حل هذه المشاكل',
          useralTransfer: {
            error: 'يجب أولاً نقل جميع البضائع إلى المستخدم'
          },
          send: {
            success: 'تم إرسال الطلب بنجاح',
            error: 'رافق إرسال الطلب خطأ',
            notTransfer: 'لم يتم نقل المنتج المعني إلى المستخدم'
          },
          choose: {
            dynamicSuccess: ' {name} المنتج المحدد'
          },
          get: {
            success: 'تم تقديم الطلب بنجاح',
            error: 'رافق إرسال الطلب خطأ',
            getAll: 'استلام بضائع المتجر'
          },
          publish: {
            success: 'تم التحقق من معلومات السلع الأساسية بنجاح',
            error: 'حدث خطأ أثناء التحقق من معلومات البضائع'
          },
          transfer: {
            success: 'تم إرسال الطلب بنجاح',
            error: 'رافق إرسال الطلب خطأ',
            transferAll: 'تم نقل جميع البضائع إلى المستخدم'
          },

          insert: {
            success: 'تم تسجيل معلومات المنتج بنجاح في المتجر',
            error: 'حدث خطأ في تسجيل معلومات المنتج'
          },

          edit: {
            success: 'تم تحديث معلومات المنتج بنجاح في المتجر',
            error: 'فشل تحديث معلومات المنتج'
          },

          delete: {
            success: 'تم حذف معلومات المنتج بنجاح',
            error: 'كان حذف معلومات المنتج مصحوبًا بخطأ'
          },

          parseError: {
            wrongValues: 'المعلومات التي تم إدخالها غير صحيحة'
          }
        },

        list: {
          title: 'مراقبة بضائع الموقع',
          dynamicTitle: 'التحكم في البضائع {name}',

          status: {
            all: 'الجميع',
            notConnect: 'لا يوجد اتصال',
            draft: 'مسودة',
            waiting: 'منتظر',
            published: 'نشرت',
            personal: 'نشر'
          },

          inStockTypes: {
            available: 'موجود في المستودع',
            notAvailable: 'غير متوفره'
          },

          table: {
            header: {
              rowNumber: 'صف',
              image: 'صورة',
              code: 'هوية شخصية',
              type: 'اكتب',
              creator: 'المنشئ',
              name: 'لقب',
              category: 'التجمع',
              technicalProblem: 'مشكلة فنية',
              status: 'حالة الإصدار',
              backOrdered: 'حالة ما قبل البيع',
              inStock: 'حالة الجرد',
              manageStock: 'ادارة المخزون',
              receivedDate: 'تاريخ الاستلام',
              transferredDate: 'تاريخ التحويل',
              send: 'إرسال'
            }
          },

          variantTable: {
            header: {
              rowNumber: 'صف',
              receivedId: 'معرف المتغير',
              variant: 'عامل',
              stock: 'جرد',
              barcode: 'الباركود',
              price: 'السعر',
              transferStatus: 'حالة نقل',
              published: 'حالة الإصدار',
              inStock: 'حالة الجرد'
            }
          }
        },

        profile: {
          title: '',

          importantData: {
            price: 'السعر',
            salePrice: 'مزاد السعر',
            type: 'اكتب'
          },

          actions: {
            attributes: 'مواصفات المنتج',
            variant: 'متغيرات المنتج',
            logs: 'سجلات المنتج'
          }
        },

        insert: {
          title: 'أضف منتج جديد',

          notifications: {
            insertSoon: 'حتى لحظة أخرى ، يتم تخزين معلومات المنتج في WooController'
          }
        },

        edit: {
          title: 'تحرير البضائع',

          labels: {
            salePrice: 'مزاد السعر',
            manageStock: 'ادارة المخزون',
            backOrdered: 'بريسيل',
            attributeInsert: 'قم بإنشاء متغير جديد'
          },

          description: {
            editVariant: 'يمكنك تحرير المعلومات حول كل متغير في متجرك من خلال هذه الصفحة.',
            error: 'العملية كانت مرتبطة بخطأ',
            noLog: 'لا يوجد سجل لهذا المنتج'
          },

          notifications: {
            variantExist: 'هناك متغير مع هذه الخاصية',
            salePrice: 'لا يمكن أن يكون سعر المزاد أعلى من السعر الأصلي',
            dynamicSalePrice: 'سعر المزاد المتغير مع الرمز الشريطي {barcode}  لا يمكن أن يكون أكثر من السعر الأصلي'
          }
        },

        logs: {
          title: 'سجلات السلع',
          dynamicTitle: 'السجلات {name}',

          descriptions: 'وصف السجل',

          table: {
            header: {
              rowNumber: 'صف',
              description: 'وصف',
              date: 'تاریخ'
            }
          }
        },

        attributes: {
          title: '',
          dynamicTitle: 'ميزات {name}',

          table: {
            header: {
              rowNumber: 'صف',
              key: 'عنوان الميزة',
              value: 'قيمة السمة'
            }
          }
        },

        variant: {
          title: '',
          dynamicTitle: 'متغيرات {name}',

          table: {
            header: {
              logs: 'السجلات',
              transferredDate: 'تاريخ التحويل',
              receivedDate: 'تاريخ الاستلام',
              creator: 'المنشئ',
              salePrice: 'مزاد السعر',
              price: 'السعر',
              barcode: 'الباركود',
              stock: 'جرد'
            }
          },

          edit: {
            labels: {
              barcode: 'الباركود',
              price: 'السعر',
              salePrice: 'مزاد السعر',
              stock: 'جرد',
              manageStock: 'ادارة المخزون',
              backOrdered: 'بريسيل',
              delete: 'إزالة البضائع'
            },

            confirmations: {
              delete: {
                title: 'تأكيد',
                body: 'هل أنت متأكد أنك تريد حذف هذا المتغير؟ إذا تم التأكيد ، ستتم أيضًا إزالة معلومات المنتج من متجرك!'
              }
            }
          },

          logs: {
            dynamicTitle: 'سجلات متغيرة مع رمز شريطي {barcode}'
          },

          notifications: {
            delete: {
              success: 'تم حذف معلومات المتغير بنجاح',
              error: 'ارتبط حذف المعلومات المتغيرة بخطأ'
            }
          }
        }
      },

      inventory: {
        title: 'مراقبة المخزون',
        dynamicTitle: 'مراقبة المخزون {name}',

        labels: {
          notAvailable: 'غير متوفره'
        },

        table: {
          header: {
            rowNumber: 'صف',
            image: 'صورة',
            code: 'هوية شخصية',
            barcode: 'الباركود',
            name: 'لقب',
            variant: 'عامل',
            woocommerceStock: 'جرد الموقع',
            useralStock: 'یوزرال',
            updateDate: 'تاريخ تحديث الموقع',
            sync: 'إرسال'
          }
        },

        confirmations: {
          syncUseral: {
            title: 'رسالة',
            body: 'هل أنت متأكد أنك تريد نقل المخزون إلى المستخدم؟'
          },

          syncWoocommerce: {
            title: 'رسالة',
            body: 'هل أنت متأكد أنك تريد إرسال المخزون إلى WooCommerce؟'
          },

          receive: {
            title: 'رسالة',
            body: 'هل أنت متأكد من أنك تريد استلام مخزون من WooCommerce؟'
          }
        },

        notifications: {
          transferAll: 'يجب أولاً نقل جميع البضائع إلى المستخدم',
          sync: {
            success: 'تم تحديث جرد هذا المنتج على الموقع',
            error: 'كان تحديث مخزون المنتج على الموقع مصحوبًا بخطأ'
          },

          requestSend: {
            success: 'تم ارسال الطلب',
            error: 'رافق إرسال الطلب خطأ'
          }
        }
      },

      prices: {
        title: 'التحكم في السعر',
        dynamicTitle: 'التحكم في السعر {name}',

        table: {
          header: {
            rowNumber: 'صف',
            image: 'صورة',
            code: 'هوية شخصية',
            barcode: 'الباركود',
            name: 'لقب',
            variant: 'عامل',
            woocommercePrice: 'سعر الموقع',
            useralPrice: 'سعر یوزرال',
            woocommerceSalePrice: 'سعر المزاد الموقع',
            useralSalePrice: 'سعر المزاد یوزرال',
            updateDate: 'تاريخ تحديث الموقع',
            update: 'تحديث'
          }
        },

        notifications: {
          transferToUseral: 'يجب أولاً نقل جميع البضائع إلى المستخدم',
          notTransfer: 'لم يتم نقل المنتج للمستخدم',
          inventoryCorrect: 'جرد هذا المنتج على الموقع صحيح',

          requestSend: {
            success: 'تم ارسال الطلب',
            error: 'رافق إرسال الطلب خطأ'
          },

          edit: {
            success: 'تم تحديث جرد هذا المنتج على الموقع',
            error: 'كان تحديث مخزون المنتج على الموقع مصحوبًا بخطأ'
          }
        }
      },

      paymentGateways: {
        title: 'التحكم في طرق الدفع',
        dynamicTitle: 'التحكم في طرق الدفع {name}',

        statusTypes: {
          all: 'الجميع',
          notConnect: 'لا يوجد اتصال',
          active: 'نشيط',
          disActive: 'غير نشط'
        },

        table: {
          header: {
            rowNumber: 'صف',
            title: 'عنوان المنفذ',
            status: 'شرط',
            receivedDate: 'تاريخ الاستلام',
            transferredDate: 'تاريخ التحويل',
            send: 'إرسال'
          }
        },

        confirmations: {
          get: {
            title: '',
            body: 'هل أنت متأكد أنك تريد الحصول على قائمة السلع؟'
          }
        },

        notifications: {
          notTransfer: 'لم يتم تحويل طريقة استلام المبلغ المطلوب للمستخدم',
          receivedProducts: 'استلام بضائع المتجر',

          requestSend: {
            success: 'تم إرسال الطلب بنجاح',
            error: 'رافق إرسال الطلب خطأ'
          },

          requestSave: {
            success: 'تم تقديم الطلب بنجاح'
          }
        },

        sync: {
          title: '',
          dynamicTitle: 'نقل الميناء «{name}» الی یوزرال',

          types: {
            internet: 'بوابة الإنترنت',
            pos: 'قارئ بطاقة'
          },

          labels: {
            bank: 'بنك التسوية',
            type: 'نوع المنفذ',
            endTime: 'نهاية يوم عمل الميناء',
            descriptions: 'وصف:'
          },

          notifications: {
            parseError: {
              bank: 'لم يتم تحديد بنك التسوية',
              type: 'نوع المنفذ غير محدد',
              endTime: 'لم يتم إدخال وقت انتهاء يوم عمل الميناء بشكل صحيح'
            }
          }
        }
      },

      orders: {
        title: 'مراقبة الفاتورة',

        invoiceProcesses: {
          notConnect: 'لا يوجد اتصال',
          paymentWaiting: 'انتظار الدفع',
          doing: 'عمل',
          reviewWaiting: 'في انتظار المراجعة',
          completed: 'مكتمل',
          cancel: 'ألغيت',
          rejected: 'مشار إليه',
          unSuccess: 'غير ناجح',
          deleted: 'تم الحذف'
        },

        list: {
          title: 'قائمة أوامر المتجر',
          dynamicTitle: 'قائمة أوامر المتجر {name}',

          technicalProblemTypes: {
            all: 'الجميع',
            have: 'حصل عليها',
            notHave: 'لا يمتلك'
          },

          statusTypes: {
            all: 'الجميع',
            preInvoice: 'فاتورة الشراء المسبق',
            invoice: 'فاتورة الشراء',
            storeReceipt: 'ايصالات الايداع'
          },

          labels: {
            unknown: 'غير محدد'
          },

          table: {
            header: {
              invoiceNumber: 'رقم الفاتورة',
              receivedId: 'رقم التعريف الخاص بالطلب',
              status: 'شرط',
              user: 'جانب الحساب',
              date: 'تاریخ',
              finalPrice: 'القيمة النهائية',
              totalDiscount: 'إجمالي الخصم',
              technicalProblem: 'مشكلة فنية',
              send: 'إرسال'
            }
          },

          notifications: {
            notTransfer: 'لم يتم تحويل الفاتورة للمستخدم',

            requestSend: {
              success: 'تم إرسال الطلب بنجاح',
              error: 'رافق إرسال الطلب خطأ'
            }
          }
        },

        order: {
          title: 'فاتورة طلب سلعة',
          dynamicTitle: 'فاتورة برقم {number}',

          labels: {
            user: 'جانب الحساب',
            id: 'هوية صوتية',
            date: 'تاريخ التسجيل',
            status: 'شرط'
          },

          placeholders: {
            id: 'تلقائي'
          },

          table: {
            header: {
              rowNumber: 'صف',
              code: 'الباركود',
              name: 'كالا',
              variant: 'عامل',
              store: 'متجر',
              count: 'رقم',
              unitPrice: 'وحدة المبلغ',
              totalPrice: 'المبلغ الإجمالي',
              discount: 'خصم',
              finalPrice: 'القيمة النهائية'
            }
          }
        },

        logs: {
          title: 'سجلات فاتورة طلب المنتج',
          dynamicTitle: 'طلب سجلات فاتورة برقم {number}',

          descriptions: 'وصف السجل',

          table: {
            header: {
              rowNumber: 'صف',
              description: 'وصف',
              date: 'تاریخ'
            }
          }
        }
      },

      users: {
        title: 'تحكم العملاء',
        dynamicTitle: 'تحكم العملاء {name}',

        technicalProblemTypes: {
          all: 'الجميع',
          have: 'حصل عليها',
          notHave: 'لا يمتلك'
        },

        labels: {
          useral: 'یوزرال',
          unknown: 'مجهول'
        },

        table: {
          header: {
            rowNumber: 'صف',
            avatar: 'الصورة الرمزية',
            receivedId: 'هوية الزبون',
            fullName: 'الاسم الأول واسم العائلة',
            company: 'شركة',
            phoneNumber: 'رقم التليفون',
            technicalProblem: 'مشكلة فنية',
            receivedDate: 'تاريخ الاستلام',
            transferredDate: 'تاريخ التحويل',
            send: 'إرسال'
          }
        },

        confirmations: {
          sync: {
            title: '',
            body: 'هل أنت متأكد أنك تريد نقل معلومات العميل إلى المستخدم؟'
          },

          technicalError: {
            title: 'خطأ',
            body: 'توجد مشكلة فنية في بعض معلومات العملاء ، يرجى إصلاحها'
          },

          getUsers: {
            title: '',
            body: 'هل أنت متأكد أنك تريد استلام قائمة العملاء؟'
          }
        },

        notifications: {
          syncUsers: 'يتم استلام معلومات جميع العملاء من الموقع',
          notTransfer: 'لم يتم نقل العميل المعني إلى المستخدم',
          transferToUseral: 'يتم نقل جميع معلومات العميل إلى المستخدم',

          requestSend: {
            success: 'تم إرسال الطلب بنجاح',
            error: 'رافق إرسال الطلب خطأ'
          },

          requestSave: {
            success: 'تم تقديم الطلب بنجاح'
          }
        },

        logs: {
          description: 'وصف السجل',

          table: {
            header: {
              rowNumber: 'صف',
              description: 'وصف',
              date: 'تاریخ'
            }
          }
        },

        edit: {
          title: '',
          dynamicTitle: 'تعديل {name}',

          dataCategory: {
            generalInfo: 'معلومات عامة'
          },

          labels: {
            name: 'اسم',
            family: 'الكنية',
            company: 'شركة',
            phoneNumber: 'رقم الهاتف المحمول',
            email: 'البريد الإلكتروني',
            delete: 'حذف العميل',
            customerLogs: 'سجلات العملاء'
          },

          descriptions: {
            noLog: 'لا يوجد سجل لهذا الشخص'
          },

          confirmations: {
            delete: {
              title: 'تأكيد',
              body: 'هل أنت متأكد أنك تريد حذف هذا العميل؟ إذا تم التأكيد ، ستتم أيضًا إزالة معلومات العميل من موقع الويب الخاص بك!'
            }
          },

          notifications: {
            wrongInputValues: 'المعلومات التي تم إدخالها غير صحيحة',

            requestSend: {
              success: 'تم ارسال الطلب',
              wait: 'تم ارسال الطلب. أرجو الإنتظار...'
            },

            edit: {
              success: 'تم تحديث المعلومات الشخصية بنجاح',
              error: 'خطأ في تحديث المعلومات الشخصية'
            },

            delete: {
              error: 'ارتبط حذف المعلومات الشخصية بخطأ'
            }
          }
        }
      },

      setting: {
        title: 'إعدادات الموصل'
      }
    }
  },

  // setting page
  setting: {
    title: 'إعدادات',

    importantData: {
      balance: 'جرد',
      employeeCount: 'عدد المستخدمين'
    },

    buttons: {
      save: 'احفظ التغييرات',
      reset: 'التراجع عن التغييرات',
      removeLogo: 'قم بإزالة الشعار',
      uploadLogo: 'قم بتحميل الشعار'
    },

    Company: {
      title: 'شركة'
    },

    access: {
      title: 'مجموعات الاعضاء',


      notifications: {
        insert: {
          success: 'تمت إضافة مجموعة المستخدمين بنجاح',
          error: 'إضافة مجموعة مستخدمين كانت مرتبطة بخطأ'
        },

        edit: {
          success: 'تم تحرير مجموعة المستخدمين بنجاح',
          error: 'كان تحرير مجموعة المستخدمين مصحوبًا بخطأ'
        },

        delete: {
          success: 'تم حذف مجموعة المستخدمين بنجاح',
          error: 'لم يتم العثور على مجموعة المستخدم المطلوبة.',
          cantDelete: 'لا يمكن حذف مجموعة المستخدمين المطلوبة بسبب وجود مجموعة فرعية.'
        }
      },

      confirmations: {
        delete: {
          title: 'حذف مجموعة المستخدمين',
          body: 'هل أنت متأكد أنك تريد حذف  {name} ؟'
        }
      },


      insert: {
        title: 'أضف مجموعة مستخدمين جديدة',

        labels: {
          name: 'عنوان مجموعة المستخدم ',
          access: 'التمكن من',
          insert: 'يضيف',

          accessList: {
            manager: 'وصول المدير العام',

            users: {
              totalAccess: 'الوصول الكامل للأشخاص',
              show: 'رؤية الناس',
              insert: 'أضف شخصًا',
              update: 'تحرير كامل',
              delete: 'الإبعاد المؤقت للشخص',
              setRole: 'قم بتعيين الأدوار للشخص'
            },

            roles: 'الوصول الكامل إلى الأدوار',
            treasury: {
              title: 'الوصول الكامل إلى الخزينة',

              bank: 'الوصول الكامل للبنوك',
              cash: 'الوصول الكامل إلى الأموال'
            },

            reports: {
              title: 'الوصول الكامل إلى التقارير',
              logs: 'سجلات الوصول'
            },

            delete: {
              title: 'الحذف الدائم',

              user: 'الناس',
              banks: 'البنوك',
              cash: 'مربعات'
            }
          }
        },

        validators: {
          name: 'لم يتم إدخال اسم المجموعة بشكل صحيح',
          nameCantEmpty: 'لا يمكن أن يكون الاسم فارغًا',
          access: 'لم يتم تحديد وصول'
        }
      },

      edit: {
        title: 'تحرير مجموعة المستخدم'
      },

      table: {

        header: {
          row: 'صف',
          name: 'عنوان الوصول'
        }
      },

      actions: {
        insert: 'يضيف',
        save: 'سجل'
      }
    },


    users: {
      title: 'اعدادات شخصية',

      labels: {
        gender: 'الجنس الإجباري'
      }
    },

    security: {
      title: 'الأمن',

      labels: {
        disActive: 'مدة المغادرة القسرية إذا لم يكن هناك نشاط',
        minute: 'الدقائق'
      }
    },

    customize: {
      title: 'تخصيص النظام',

      labels: {
        theme: 'حدد الموضوع المطلوب'
      }
    },

    General: {
      title: 'عام',
      logo: {
        rule: 'تنسيقات JPG أو JPEG أو PNG المعتمدة. الحد الأقصى لحجم الشعار 800 كيلو بايت'
      },
      club: {
        code: 'كود الشركة',
        name: 'اسم الشركة',
        managerName: 'اسم المدير',
        address: 'عنوان',
        phoneNumber: 'رقم التليفون',
        managerPhoneNumber: 'رقم الاتصال بالمدير'
      }
    },

    Treasury: {
      title: 'خزينة',
      rule: 'قواعد',
      NegativeValue: 'البنوك والصناديق يمكن أن تكون سلبية'
    },

    Locale: {
      title: 'موقع',
      language: 'لسان',
      basicSetting: 'الإعدادات الأساسية',


      labels: {
        defaultLanguage: 'اللغة الافتراضية للشركة'
      }
    },

    Store: {
      title: 'متجر'
    },

    Buy: {
      title: 'شراء'
    },

    Sale: {
      title: 'بيع'
    }
  },

  // Products Pages
  products: {
    title: 'بضائع وخدمات',

    list: {
      title: 'قائمة السلع والخدمات',

      table: {
        header: {
          row: 'صف',
          name: 'عنوان المنتج',
          code: 'الرمز الشريطي',
          barcode: 'الباركود',
          price: 'السعر',
          image: 'صورة المنتج',
          category: 'التجمع',
          creator: 'الخالق',
          type: 'النوع',
          status: 'حالة',
          shops: 'المخازن',
          lastModifiedDate: 'تاريخ التحديث'
        },

        filters: {
          status: {
            all: 'جميع',
            notConnect: 'لا يوجد اتصال',
            draft: 'مسودة',
            waiting: 'انتظار',
            published: 'نشرت',
            personal: 'خاص'
          },

          type: {
            all: 'جميع',
            simpleProduct: 'سلع بسيطة',
            variableProduct: 'البضائع المتغيرة'
          }
        }
      }
    },

    insert: {
      title: 'أضف البضائعا',

      types: {
        label: 'حدد النوع الذي تريد إضافته',
        useral: 'اضافة الى یوزرال',
        woocommerce: 'اضافة الى یوزرال'
      },

      table: {
        header: {
          inventory: 'المخزون',
          barcode: 'الباركود',
          price: 'السعر',
          salePrice: 'مزاد السعر',
          delete: 'حذف'
        }
      },

      description: {
        title: 'الوصف',

        useralDescription: 'يتم تسجيل معلومات هذا المنتج في نظام یوزرال فقط.',
        woocommerceDescription: 'يتم تسجيل معلومات المنتج مباشرة في متجرك قيد المراجعة.',
        notCategorySelected: 'لم يتم اختيار أي فئات',
        noAttributesExistForCategory: 'لا توجد سمات لهذه الفئة',
        noAttributeChooseForVariables: 'لم يتم تحديد سمة كمتغير',
        inventory: 'في هذه الصفحة يمكنك إدخال مخزون المنتج بالكامل. إذا كان رقم المخزون صفرًا ، فسيتم اعتبار المنتج غير موجود.',
        attribute: 'يمكنك تحديد الميزة المطلوبة وإضافتها إلى قائمة ميزات المنتج. إذا كان المنتج متغيرًا ، فيمكنك تحديد <b> واحد </ b> من الميزات كميزة متغيرة للمنتج'
      },

      notifications: {
        type: {
          notSelected: {
            error: 'نوع الإضافة غير محدد'
          }
        },
        notConnectToServer: {
          error: 'لن تتمكن من إضافة المنتج بسبب عدم الاتصال بالموقع'
        },
        imageUpload: {
          success: 'تم تحميل الصورة بنجاح'
        },
        imageRemove: {
          success: 'تمت إزالة الشعار بنجاح'
        },
        insertProduct: {
          success: 'تم تسجيل معلومات المنتج بنجاح',
          error: 'حدث خطأ في تسجيل معلومات المنتج'
        },
        woocommerceInsertProduct: {
          success: 'تم تسجيل معلومات المنتج بنجاح في المتجر',
          error: 'حدث خطأ في تسجيل معلومات المنتج'
        }
      },

      accordionList: {
        brand: {
          title: 'اختر الماركة المرغوبة',

          insert: {
            title: 'أضف علامة تجارية جديدة',
            brand: 'عنوان العلامة التجارية'
          },

          edit: {
            title: 'قم بتحرير العلامة التجارية',
            category: 'عنوان العلامة التجارية'
          },

          delete: {
            title: 'قم بإزالة العلامة التجارية',
            message: 'بحذف هذه العلامة التجارية ، سيتم أيضًا حذف جميع المعلومات ذات الصلة.'
          }
        },

        category: {
          title: 'حدد الفئة المطلوبة',

          insert: {
            title: 'إضافة فئة جديدة',
            category: 'عنوان الفئة'
          },

          edit: {
            title: 'تحرير الفئات',
            category: 'عنوان الفئة'
          },

          delete: {
            title: 'حذف الفئات',
            message: 'سيؤدي حذف هذه الفئة أيضًا إلى حذف جميع المعلومات ذات الصلة.'
          }
        },


        attributes: 'صفات',
        insertAttribute: {
          title: 'أضف ميزة جديدة',
          attribute: 'عنوان الميزة',
          values: 'مبالغ'
        },
        editAttribute: {
          title: 'تحرير الميزة',
          attribute: 'عنوان الميزة',
          values: 'مبالغ'
        },

        buttons: {
          accept: 'تأكيد',
          cancel: 'يلغي'
        },

        alert: {
          title: 'رسالة',
          brandCreated: 'تم إنشاء العلامة التجارية بنجاح',
          brandUpdated: 'تم تحرير العلامة التجارية بنجاح',
          brandDeleted: 'تمت إزالة العلامة التجارية بنجاح',
          categoryCreated: 'تم إنشاء الفئة بنجاح',
          categoryUpdated: 'تم تحرير الفئة بنجاح',
          categoryDeleted: 'تمت إزالة الفئة بنجاح',
          errorTitle: 'خطأ',
          error: 'العملية كانت مصحوبة بخطأ'
        }
      }
    },

    edit: {
      title: 'تحرير البضائع',
      dynamicTitle: 'تعديل {name}',

      table: {
        header: {
          inventory: 'جرد',
          barcode: 'الباركود',
          price: 'السعر',
          salePrice: 'مزاد السعر',
          delete: 'حذف'
        }
      },

      description: {
        title: 'وصف',

        variableDescription: 'يمكنك تحرير المعلومات حول كل متغير في متجرك من خلال هذه الصفحة.',
        useralDescription: 'يتم تسجيل معلومات هذا المنتج في نظام المستخدم فقط.',
        woocommerceDescription: 'يتم تسجيل معلومات المنتج مباشرة في متجرك قيد المراجعة.',
        notCategorySelected: 'لم يتم تحديد فئات',
        noAttributesExist: 'لا توجد سمات لهذه الفئة',
        noAttributeChooseForVariables: 'لم يتم تحديد سمة كمتغير.',
        inventory: 'في هذه الصفحة يمكنك إدخال مخزون المنتج بالكامل. إذا كان رقم المخزون صفرًا ، فسيتم اعتبار المنتج غير موجود.',
        attribute: 'يمكنك تحديد الميزة المطلوبة وإضافتها إلى قائمة ميزات المنتج. إذا كان المنتج متغيرًا ، فيمكنك تحديد <b> واحد </ b> من الميزات كميزة متغيرة للمنتج'
      },

      notifications: {
        type: {
          notSelected: {
            error: 'نوع الإضافة غير محدد'
          }
        },
        notConnectToServer: {
          error: 'لن تتمكن من إضافة المنتج بسبب عدم الاتصال بالموقع'
        },
        imageUpload: {
          success: 'تم تحميل الصورة بنجاح'
        },
        imageRemove: {
          success: 'تمت إزالة الشعار بنجاح'
        },
        editProduct: {
          success: 'تم تسجيل معلومات المنتج بنجاح',
          error: 'حدث خطأ في تسجيل معلومات المنتج'
        },
        woocommerceEditProduct: {
          success: 'تم تسجيل معلومات المنتج بنجاح في المتجر',
          error: 'حدث خطأ في تسجيل معلومات المنتج'
        },
        deleteVariant: {
          success: 'تم حذف معلومات المتغير بنجاح',
          error: 'ارتبط حذف المعلومات المتغيرة بخطأ'
        },
        deleteProduct: {
          success: 'تم حذف معلومات المنتج بنجاح',
          error: 'كان حذف معلومات المنتج مصحوبًا بخطأ'
        }
      },

      confirmations: {
        removeProduct: {
          title: 'تأكيد',
          message: 'هل أنت متأكد أنك تريد حذف هذا البند؟'
        },
        removeWoocommerceProduct: {
          title: 'تأكيد',
          body: 'هل أنت متأكد أنك تريد حذف هذا البند؟ إذا تم التأكيد ، ستتم أيضًا إزالة معلومات المنتج من متجرك!'
        },
        changeProductType: {
          title: 'تحذير',
          body: 'إذا تم تغيير نوع المنتج المتغير إلى بسيط ، فسيتم حذف معلومات جميع المتغيرات'
        },
        deleteVariable: {
          title: 'تأكيد',
          body: 'هل أنت متأكد أنك تريد حذف هذا المتغير؟ إذا تم التأكيد ، ستتم أيضًا إزالة معلومات المنتج من متجرك!'
        }
      }
    },

    product: {
      title: 'كالا',

      labels: {
        withoutPrice: 'لا ثمن'
      },

      importantData: {
        price: 'السعر',
        salePrice: 'مزاد السعر',
        category: 'التجمع',
        type: 'اكتب'
      },

      actions: {
        turnover: 'معدل دوران السلع',
        attributes: 'مواصفات المنتج',
        variants: 'متغيرات المنتج'
      },


      attributes: {
        title: 'صفات',
        dynamicTitle: 'ميزات {name}',

        table: {
          header: {
            rowNumber: 'صف',
            key: 'عنوان الميزة',
            value: 'قيمة السمة'
          }
        }
      },

      turnover: {
        title: 'معدل دوران السلع',
        dynamicTitle: 'تداول {name}',

        table: {
          header: {
            rowNumber: 'صف',
            type: 'نوع الحوالة',
            date: 'تاريخ',
            variant: 'عامل',
            destination: 'جانب الحساب',
            quantity: 'رقم',
            unitPrice: 'وحدة المبلغ',
            status: 'شرط'
          }
        },

        typeMapper: {
          openingBalance: 'الدورة الأولى',
          receive: 'ايصالات الايداع',
          exit: 'رحيل المستودع',
          transfer: 'انتقالية'
        },

        statusMapper: {
          sent: 'تم النشر',
          onWay: 'فى الطريق',
          received: 'وصل إلى المستودع',
          waitingToSend: 'في انتظار الإرسال'
        }
      },

      inventory: {
        title: 'جرد',
        dynamicTitle: 'الجرد {name}',

        table: {
          header: {
            rowNumber: 'صف',
            storeroom: 'متجر',
            openingInventory: 'الدورة الأولى ',
            received: 'وصل إلى المستودع',
            sent: 'تم النشر',
            minInventory: 'الحد الأدنى من المخزون',
            onWay: 'فى الطريق',
            totalInventory: 'إجمالي المخزون',
            waitingToSend: 'في انتظار الإرسال',
            saleInventory: 'المخزون المستهلك'
          }
        }
      },

      variants: {

        title: 'متغيرات السلع',
        dynamicTitle: 'متغيرات {name}',

        table: {
          header: {
            barcode: 'الباركود',
            price: 'السعر',
            salePrice: 'مزاد السعر'
          }
        }
      },


      type: {
        simple: 'سلع بسيطة',
        variant: 'البضائع المتغيرة',
        service: 'خدمة'
      }
    },

    select: {
      title: 'قائمة البضائع',

      table: {
        header: {
          select: 'اختيار',
          rowNumber: 'صف',
          name: 'عنوان المنتج',
          code: 'الباركود',
          barcode: 'الباركود',
          price: 'السعر',
          image: 'صورة المنتج',
          category: 'التجمع',
          type: 'اكتب',
          variant: 'عامل',
          salePrice: 'مزاد السعر',
          quantity: 'جرد',
          storeroom: 'متجر',
          edit: 'يحرر'
        },

        filters: {
          type: {
            all: 'الجميع',
            simple: 'سلع بسيطة',
            variant: 'البضائع المتغيرة'
          }
        }
      }
    },

    trash: {

      title: 'نفايات البضائع والخدمات',

      table: {
        header: {
          rowNumber: 'صف',
          name: 'عنوان المنتج',
          code: 'الباركود',
          image: 'صورة المنتج',
          type: 'اكتب',
          delete: 'حذف',
          restore: 'يعيد'
        }
      },

      confirmations: {
        delete: {
          title: 'تأكيد إزالة البضائع',
          body: '{name}هل أنت متأكد أنك تريد حذفه نهائيًا؟'
        }
      },

      notifications: {
        restore: {
          success: 'تم إرجاع البضائع بنجاح',
          error: 'رافق إرجاع البضاعة خطأ'
        },
        delete: {
          success: 'تمت إزالة المنتج بنجاح',
          error: 'كان حذف المنتج مصحوبًا بخطأ'
        }
      }
    },

    openingBalance: {
      productList: 'قائمة البضائع',

      insert: {
        title: 'أضف دورة الأسهم الأولى'
      },


      productType: {
        simple: 'سلع بسيطة',
        variant: 'البضائع المتغيرة',
        service: 'خدمة'
      },

      labels: {
        count: 'رقم',
        price: 'السعر',
        delete: 'إزالة الدورة الأولى',
        insertProduct: 'أضف منتج جديد'
      },

      table: {
        header: {
          rowNumber: 'صف',
          barcode: 'الباركود',
          name: 'كالا',
          variant: 'عامل',
          count: 'رقم',
          unitPrice: 'وحدة المبلغ',
          totalPrice: 'المبلغ الإجمالي',
          delete: 'حذف'
        }
      },

      validators: {
        valueExist: 'هذه القيمة موجودة في القائمة',
        productExist: '{name} يوجد في القائمة',
        barcode: 'لايوجد منتج بهذا الباركود او انه موجود في الفترة الاولى',
        lock: 'الأول هو فترة القفل'
      },

      notifications: {
        insert: {
          success: 'تم تسجيل أول فترة تخزين بنجاح',
          error: 'التسجيل الأول لفترة المستودع رافقه خطأ!!'
        },
        edit: {
          success: 'تم تحديث فترة المنتج الأولى بنجاح',
          error: 'كان التحديث الأول لفترة المنتج مصحوبًا بخطأ'
        },
        delete: {
          success: 'تمت إزالة الفترة الأولى للمنتج بنجاح',
          error: 'رافق الحذف الأول لفترة المنتج المطلوبة خطأ'
        }
      },

      confirmations: {
        delete: {
          title: 'تأكيد الحذف',
          body: 'هل أنت متأكد أنك تريد حذف المنتج الأول؟'
        }
      }
    },

    labels: {
      type: 'نوع المنتج',
      id: 'هوية شخصية',
      barcode: 'الباركود',
      productName: 'اسم المنتج',
      serviceName: 'اسم الخدمة',
      category: 'التجمع',
      serviceCategory: 'فئة الخدمة',
      price: 'السعر',
      productPrice: 'أسعار السلع',
      servicePrice: 'سعر الخدمة',
      salePrice: 'مزاد السعر',
      description: 'وصف',
      inventory: 'جرد',
      chooseAttribute: 'اختيار ميزة',
      chooseCategory: 'اختر تصنيف',
      withoutCategory: 'لا تصنيف',
      removeProduct: 'إزالة البضائع',
      createVariable: 'قم بإنشاء متغير جديد',
      removeVariable: 'حذف المتغير'
    },

    validators: {
      wrongInputValues: 'المعلومات التي تم إدخالها غير صحيحة',
      imageSize: 'حجم الملف المحدد كبير جدًا',
      fileFormat: 'تنسيق الملف المحدد غير مسموح به',
      variantExist: 'هناك متغير مع هذه الخاصية',
      variantListTotalCount: 'إجمالي عدد الأوضاع الممكنة يساوي {max}',
      salePrice: 'لا يمكن أن يكون سعر المزاد أعلى من السعر الأصلي',
      variableSalePrice: ' {barcode}لا يمكن أن يكون سعر المزاد المتغير مع الباركود أكثر من السعر الأصلي',
      code: 'يوجد منتج بهذا الرمز أو الرمز خاطئ',
      barcode: 'يوجد منتج بهذا الرمز الشريطي ، أو الباركود خاطئ',
      variableBarcode: 'الرمز الشريطي هو متغير صف مكرر {row}'
    },

    dataCategory: {
      generalInfo: 'معلومات عامة',
      attributes: 'صفات',
      variables: 'المتغيرات'
    },

    productType: {
      simple: 'بسيط',
      variant: 'عامل',
      service: 'خدمة'
    },

    pricing: {
      title: 'تسعير المنتجات',

      table: {
        header: {
          rowNumber: 'صف',
          name: 'عنوان المنتج',
          code: 'الباركود',
          barcode: 'الباركود',
          price: 'السعر',
          salePrice: 'مزاد السعر',
          image: 'صورة المنتج',
          category: 'التجمع',
          variant: 'عامل',
          creator: 'المنشئ',
          type: 'اكتب',
          status: 'شرط',
          shops: 'المخازن',
          lastModifiedDate: 'تاريخ التحديث'
        },

        filters: {
          type: {
            all: 'الجميع',
            simpleProduct: 'سلع بسيطة',
            variableProduct: 'البضائع المتغيرة'
          }
        }
      },

      notifications: {
        edit: {
          success: 'تم تحديث أسعار البضائع المطلوبة بنجاح',
          error: 'رافق تحديث أسعار المنتج المطلوب خطأ'
        }
      },

      status: {
        notConnect: 'لا يوجد اتصال',
        draft: 'مسودة',
        waiting: 'منتظر',
        published: 'نشرت',
        personal: 'نشر'
      }
    },

    brands: {
      title: 'العلامات التجارية'
    }
  },

  // categories pages
  categories: {
    title: 'التجمع',


    list: {
      title: 'قائمة الفئات'
    },

    insert: {
      title: 'إضافة فئة جديدة',

      notifications: {
        insert: {
          success: 'تم إنشاء الفئة بنجاح',
          error: 'إضافة فئة ارتبطت بخطأ'
        }
      }
    },

    edit: {
      title: 'تحرير الفئات',

      notifications: {
        edit: {
          success: 'تم تحرير الفئة بنجاح',
          error: 'تم ربط فئة التحرير بخطأ'
        }
      }
    },

    delete: {
      title: '',

      notifications: {
        delete: {
          success: 'تمت إزالة الفئة بنجاح',
          error: 'حذف فئة ارتبط بخطأ'
        }
      }
    },

    labels: {
      none: 'لا أحد',
      name: 'عنوان الفئة',
      parent: 'القسم الرئيسي',
      insertAttribute: 'أضف ميزة جديدة',
      editAttribute: 'تحرير الميزة الجديدة',
      chooseAttribute: 'حدد ميزة جديدة',
      suggestAttributes: 'الميزات المقترحة',

      withoutCategory: ''
    },

    validators: {
      attributeExist: '{ name } هناك ميزات في هذه الفئة',
      noChange: 'تم إجراء أية تغييرات'
    },

    confirmations: {
      delete: {
        title: 'حذف الفئات',
        body: '{name} إذا تم حذف هذه الفئة ، فسيتم حذف جميع المنتجات في هذه الفئة. هل أنت متأكد أنك تريد حذف ذلك؟'
      }
    }
  },

  // attributes pages
  attributes: {
    title: 'صفات',

    list: {

      title: 'قائمة الميزات',

      table: {
        header: {
          rowNumber: 'صف',
          name: 'اسم الميزة',
          values: 'قيم السمات',
          categories: 'فئات الميزات'
        }
      }
    },

    insert: {
      title: 'أضف ميزة جديدة',

      notifications: {
        insert: {
          success: 'تم تسجيل الميزة الجديدة بنجاح',
          error: 'تم ربط تسجيل الميزة الجديدة بخطأ'
        },
        insertValue: {
          success: 'تم حفظ القيمة الجديدة بنجاح',
          error: 'تم حفظ القيمة الجديدة مع وجود خطأ'
        }
      }
    },

    edit: {
      title: 'تحرير الميزة',

      notifications: {
        edit: {
          success: 'تم تحرير الميزة بنجاح',
          error: 'تم اقتران تحرير الميزة بخطأ'
        },
        editValue: {
          success: 'تم تحديث قيمة الميزة بنجاح',
          error: 'تحديث القيمة الجديدة رافقه خطأ'
        }
      }
    },

    delete: {
      notifications: {
        delete: {
          success: 'تمت إزالة الميزة بنجاح',
          error: 'تم ربط إزالة الميزة بخطأ'
        }
      },

      validators: {
        delete: 'لا يمكن حذف الميزة المطلوبة'
      }
    },

    select: {
      table: {
        header: {
          select: 'اختيار',
          name: 'اسم الميزة',
          values: 'قيم السمات'
        }
      }
    },

    labels: {
      name: 'عنوان الميزة',
      slug: 'اسم الميزة',
      value: 'قيمة السمة',
      values: 'مبالغ:',
      insertValue: 'أضف قيمة جديدة',
      editValue: 'تحرير القيمة',
      categories: 'التصنيفات:',
      shops: 'المواقع:',
      delete: 'إزالة الميزة',
      withoutCategory: 'لا تصنيف'
    },

    validators: {
      name: 'لا يمكن أن يكون اسم الميزة أكثر من 14 حرفًا',
      value: 'لا يمكن أن تزيد قيمة الميزة عن 17 حرفًا',
      noChange: 'تم إجراء أية تغييرات'
    },

    confirmations: {
      delete: {

        title: 'إزالة الميزة',
        body: '{name} هل أنت متأكد أنك تريد حذف الميزة؟'
      }
    }
  },

  // Warehouse Pages
  warehouse: {
    title: 'المخزون',

    stores: {
      title: 'متجر',

      labels: {
        name: 'اسم المستودع',
        code: 'كود المستودع',
        delete: 'حذف المستودع'
      },

      list: {
        title: 'قائمة المستودعات',

        table: {
          header: {
            rowNumber: 'صف',
            code: 'الشفرة',
            name: 'عنوان المستودع',
            balance: 'جرد'
          }
        }
      },

      insert: {
        title: 'اضافة مستودع',

        notifications: {
          insert: {
            success: 'تم تسجيل معلومات المستودع الجديدة بنجاح',
            error: 'إضافة مستودع جديد رافقه خطأ'
          }
        }
      },

      edit: {
        title: 'تحرير المستودع',

        notifications: {
          edit: {
            success: 'تم تحرير معلومات المستودع بنجاح',
            error: 'تم ربط تحرير معلومات المستودع بخطأ'
          }
        }
      },

      store: {
        title: 'متجر',

        importantData: {
          code: 'معرف المستودع'
        },

        actions: {
          storeTurnover: 'تداول المستودعات',
          storeInventory: 'تصفح عدد من المنتجات المتاحة',
          storeInventoryValuation: 'راجع كمية المنتجات المتاحة'
        }
      },

      trash: {
        title: 'علبة قمامة',

        table: {
          header: {
            rowNumber: 'صف',
            code: 'الشفرة',
            name: 'عنوان المستودع',
            delete: 'حذف',
            restore: 'يعيد'
          }
        },

        confirmations: {
          delete: {
            title: 'تأكيد إزالة المستودع',
            body: '{name}هل أنت متأكد أنك تريد حذفه نهائيًا؟'
          }
        },

        notifications: {
          delete: {
            success: 'تمت إزالة المستودع بنجاح',
            error: 'كان حذف معلومات المستودع مصحوبًا بخطأ'
          },
          restore: {
            success: 'تم إرجاع المستودع بنجاح',
            error: 'ترميم المستودع رافقه خطأ'
          }
        }
      },

      delete: {
        confirmations: {
          delete: {
            title: 'تأكيد إزالة المستودع',
            body: '{name} هل أنت متأكد أنك تريد حذف المستودع مؤقتًا؟'
          }
        },

        notifications: {
          delete: {
            success: 'تم حذف معلومات المستودع بنجاح',
            error: 'كان حذف معلومات المستودع مصحوبًا بخطأ'
          }
        }
      },

      validators: {
        name: 'لم يتم إدخال اسم المستودع بشكل صحيح',
        nameCantEmpty: 'لا يمكن أن يكون اسم المستودع فارغًا',
        code: 'لم يتم إدخال رمز المستودع بشكل صحيح',
        codeCantEmpty: 'لا يمكن أن يكون كود المستودع فارغًا'
      }
    },

    turnover: {
      title: 'تداول المستودعات',
      dynamicTitle: 'تداول المستودع {name}',

      table: {
        header: {
          rowNumber: 'صف',
          name: 'عنوان المنتج',
          image: 'صورة المنتج',
          date: 'تاريخ',
          barcode: 'الباركود',
          code: 'الباركود',
          category: 'التجمع',
          variant: 'عامل',
          quantity: 'رقم',
          unitPrice: 'وحدة المبلغ',
          status: 'شرط'
        }
      },

      statusMapper: {
        sent: 'تم النشر',
        onWay: 'فى الطريق',
        received: 'وصل إلى المستودع',
        waitingToSend: 'في انتظار الإرسال'
      }
    },

    inventory: {
      title: 'تصفح عدد من المستودعات',
      dynamicTitle: 'تصفح عدد من المستودعات {name}',

      tabs: {
        inventory: 'جرد جيد',
        turnover: 'معدل دوران السلع'
      },

      table: {
        header: {
          rowNumber: 'صف',
          image: 'صورة المنتج',
          barcode: 'الباركود',
          code: 'الباركود',
          category: 'التجمع',
          name: 'عنوان المنتج',
          variant: 'عامل',
          openingInventory: 'الدورة الأولى',
          received: 'وصل إلى المستودع',
          minInventory: 'الحد الأدنى من المخزون',
          totalInventory: 'إجمالي المخزون',
          sent: 'تم النشر',
          onWay: 'فى الطريق',
          waitingToSend: 'في انتظار الإرسال',
          saleInventory: 'المخزون المستهلك'
        }
      }
    },

    inventoryValuation: {
      title: '',
      dynamicTitle: '{name} تصفح المستودع المبلغ',

      table: {
        header: {
          rowNumber: 'صف',
          image: 'صورة المنتج',
          barcode: 'الباركود',
          code: 'الباركود',
          category: 'التجمع',
          name: 'عنوان المنتج',
          variant: 'عامل',
          openingInventory: 'الدورة الأولى',
          received: 'وصل إلى المستودع',
          minInventory: 'الحد الأدنى من المخزون',
          totalInventory: 'إجمالي المخزون',
          sent: 'تم النشر',
          onWay: 'فى الطريق',
          waitingToSend: 'في انتظار الإرسال',
          saleInventory: 'المخزون المستهلك'
        }
      }
    },

    receipts: {
      title: 'تحويلات المستودعات',

      list: {
        title: 'قائمة حوالات المستودعات',

        storeName: 'المستودع {name}',

        table: {
          header: {
            id: 'رقم الإحالة',
            creator: 'المنشئ',
            accountSide: 'جانب الحساب',
            invoiceId: 'هوية صوتية',
            type: 'اكتب',
            approved: 'شرط',
            createdAt: 'تاريخ التسجيل'
          },

          filters: {
            type: {
              all: 'الجميع',
              receive: 'إيصال',
              transfer: 'انتقالية',
              exit: 'مخرج'
            },

            approved: {
              all: 'الجميع',
              confirm: 'مؤكد',
              pending: 'منتظر'
            }
          }
        },

        receiptTypes: {
          receive: 'إيصال',
          transfer: 'انتقالية',
          exit: 'مخرج'
        },

        statusTypes: {
          confirm: 'مؤكد',
          pending: 'منتظر'
        }
      },

      insert: {
        title: 'اضافة حوالات المستودع',

        labels: {
          type: 'نوع الحوالة',
          store: 'متجر',
          originStore: 'مستودع المنشأ',
          destinationStore: 'مستودع الوجهة',
          registryDate: 'تاريخ التسجيل',
          insertProduct: 'أضف منتج جديد',
          first: 'أولا',
          second: 'ثانيا'
        },

        table: {
          header: {
            barcode: 'الباركود',
            name: 'كالا',
            variant: 'عامل',
            count: 'رقم',
            unitPrice: 'وحدة المبلغ',
            totalPrice: 'المبلغ الإجمالي',
            storeroom: 'مستودع المنشأ',
            destinationStoreroom: 'مستودع الوجهة',
            delete: 'حذف'
          }
        },

        receiptTypes: {
          receive: 'ايصالات الايداع',
          transfer: 'نقل المستودع',
          exit: 'رحيل المستودع'
        }
      },

      edit: {
        title: 'تحرير تحويلات المستودع'
      },

      receipt: {
        title: 'تحويلات المستودعات',

        labels: {
          user: 'جانب الحساب',
          registryDate: 'تاريخ التسجيل',
          type: 'اكتب',
          status: 'شرط'
        },

        table: {
          header: {
            rowNumber: 'صف',
            image: 'صورة المنتج',
            code: 'الباركود',
            name: 'وصف البضاعة',
            store: 'متجر',
            originStore: 'مستودع المنشأ',
            destinationStore: 'مستودع الوجهة',
            count: 'رقم',
            unitPrice: 'وحدة المبلغ',
            totalPrice: 'المبلغ الإجمالي'
          }
        },

        validators: {
          count: '{name}يختلف الرقم عن عدد هذه البضائع في الفاتورة',
          scan: ' {name} لم يتم فحصها'
        },

        confirmation: {
          delete: {
            title: 'تأكيد الإحالة {name}',
            body: '{name}هل أنت متأكد من التحويل أعلاه؟'
          }
        },

        receiptTypes: {
          receive: 'ايصالات الايداع',
          transfer: 'نقل المستودع',
          exit: 'رحيل المستودع'
        },

        receiptActions: {
          receive: 'إيصال',
          transfer: 'التحويل',
          exit: 'مخرج'
        },

        status: {
          confirm: 'مؤكد',
          notConfirm: 'عدم الموافقة'
        }
      },

      receiptConfirm: {
        table: {
          header: {
            rowNumber: 'صف',
            barcode: 'الباركود',
            name: 'كالا',
            count: 'رقم',
            delete: 'حذف'
          }
        },

        validators: {
          notFound: 'لم يتم العثور على منتج مع هذا الرمز الشريطي',
          notExist: 'لا يوجد منتج بهذا الرمز الشريطي في الفاتورة',
          noProductChoose: 'لم يتم تحديد عناصر'
        },

        notifications: {
          insertProduct: {
            success: 'تم إضافة رقم هذا المنتج'
          },

          dynamicInsertProduct: {
            success: 'تمت إضافة الرقم {name}'
          }
        }
      },

      validators: {
        store: 'لم يتم اختيار المستودع!',
        originStore: 'لم يتم تحديد مستودع المصدر',
        destinationStore: 'مستودع الوجهة غير محدد',
        valuesExist: 'هذه القيمة موجودة في القائمة',
        productExist: '{name} يوجد في القائمة',
        noProductChoose: 'لم يتم تحديد عناصر',
        barcode: 'لم يتم العثور على منتج مع هذا الرمز الشريطي',
        price: 'لم يتم إدخال كمية البضائع!',
        productCount: 'الجرد لا يكفي!'
      },

      notifications: {
        insert: {
          success: 'تم تسجيل تحويلات المستودعات بنجاح',
          error: 'تسجيل حوالات الأنبار رافقه خطأ!'
        },

        confirm: {
          success: 'تم تسجيل تحويلات المستودعات بنجاح',
          error: 'تسجيل حوالات الأنبار رافقه خطأ!'
        }
      }
    },

    received: {
      title: 'ايصالات الايداع'
    },

    sended: {
      title: 'رحيل المستودع'
    }
  },

  // Accountancy Pages
  accountancy: {
    title: 'محاسبة',


    types: {
      title: 'جدول الحسابات',

      labels: {
        name: 'عنوان العنوان'
      },

      notifications: {
        insert: {
          success: 'تم إنشاء العنوان بنجاح',
          error: 'كان إدخال معلومات الرأس الجديدة مصحوبًا بخطأ'
        },

        edit: {
          success: 'تم إنشاء العنوان بنجاح',
          error: 'تم ربط تحديث معلومات الرأس بخطأ'
        },

        delete: {
          success: 'تمت إزالة العنوان بنجاح',
          error: 'ارتبط حذف رأس بخطأ'
        },

        parseError: {
          parent: 'العنوان الرئيسي غير محدد',
          name: 'لم يتم إدخال عنوان الموضوع'
        }
      },

      confirmations: {
        delete: {
          title: 'حذف الرأس',
          body: 'هل أنت متأكد أنك تريد حذف العنوان  {name} ؟'
        }
      },

      insert: {
        title: 'أضف موضوعًا جديدًا'
      },

      edit: {
        title: 'تحرير العناوين'
      }
    },


    openingBalance: {
      title: 'مستوى الافتتاح',

      labels: {
        assets: 'أول أصول الدورة',
        liabilities: 'ديون الفترة الأولى',
        equity: 'أول عاصمة للدورة'
      },

      notifications: {
        locked: 'الأول هو فترة القفل',
        unLocked: 'الفترة الأولى ليست مؤمنة',
        lock: {
          success: 'ترازنامه با موفقیت قفل شد',
          error: 'قفل شدن ترازنامه با خطا مواجه شد'
        },
        unlock: {
          success: 'تم قفل الميزانية العمومية بنجاح',
          error: 'حدث خطأ في إقفال الميزانية العمومية'
        }
      },

      confirmations: {
        unlock: {
          title: 'تم فتح الميزانية العمومية بنجاح',
          body: 'تم فتح الميزانية العمومية بخطأ'
        }
      }
    },

    documents: {
      title: 'قائمة الملفات',


      notifications: {
        delete: {
          success: 'تم حذف معلومات الوثيقة بنجاح',
          error: 'تم حذف معلومات المستند بنجاح'
        }
      },

      document: {
        title: 'وثيقة',

        table: {
          header: {
            rowNumber: 'صف',
            description: 'وصف',
            debt: 'مدين',
            credit: 'الدائن'
          }
        }
      },

      print: {
        cost: {
          costReceipt: 'استلام المصاريف',
          date: 'تاريخ:',
          docNumber: 'رقم الوثيقة:',
          nameAndSignature: 'الاسم والتوقيع',
          receiver: 'المتلقي',
          payer: 'دافع',
          confirmer: 'الثاني',
          print: 'مطبعة',
          back: 'العودة',
          bank: 'بنك',
          cashBox: 'مكتب النقدية',
          storeroom: 'متجر',
          paymentGateway: 'بوابة الدفع'
        }
      },

      list: {
        title: 'قائمة الملفات',

        table: {
          header: {
            id: 'رقم الوثيقة',
            createdAt: 'تاريخ',
            creator: 'المنشئ',
            description: 'توثيق',
            type: 'اكتب'
          },

          filters: {
            type: {
              all: 'الجميع',
              cash: 'يدوي',
              receive: 'تلقى',
              payment: 'الدفع',
              withdrawal: 'محصول',
              transfer: 'التحويل',
              income: 'دخل',
              cost: 'يكلف',
              openingInventory: 'الدورة الأولى',
              salary: 'حقوق',
              purchase: 'يشتري',
              returnPurchase: 'مرجع التسوق',
              sale: 'تخفيض السعر',
              returnSale: 'مرجع المبيعات',
              storeReceipt: 'ايصالات الايداع',
              storeSend: 'رحيل المستودع'
            }
          }
        },

        confirmation: {
          deleteDocument: {
            title: 'قم بتأكيد حذف المستند',
            body: 'هل أنت متأكد أنك تريد حذف هذا المستند بالكامل؟'
          }
        }
      },

      insert: {
        title: 'أضف وثيقة',

        labels: {
          documentType: 'نوع المستند',
          receive: 'تلقى',
          payment: 'الدفع',
          paymentTo: 'دفع ل.',
          receiveFrom: 'احصل من',
          withdrawalFrom: 'يأخذ من',
          depositTo: 'الإيداع',
          price: 'مقدار',
          cashBox: 'مكتب النقدية',
          bank: 'بنك',
          depositType: 'نوع الإيداع',
          choose: 'يختار',
          pos: 'POS',
          cardToCard: 'بطاقة إلى بطاقة',
          shaba: 'شبا'
        },


        notifications: {

          receive: {
            insert: {
              success: 'تم إنشاء مستند الإيصال بنجاح',
              error: 'ارتبط إنشاء مستند تنزيل بخطأ'
            }
          },

          payment: {
            insert: {
              success: 'تم إنشاء مستند الدفع بنجاح',
              error: 'تم إنشاء مستند الدفع مع وجود خطأ'
            }
          },

          parseError: {
            user: 'المستخدم غير محدد',
            cashBox: 'المربع غير محدد',
            bank: 'لم يتم اختيار البنك',
            price: 'لم يتم إدخال المبلغ',
            depositTo: 'الإيداع في البنك أو الصندوق غير محدد',
            depositType: 'لم يتم تحديد نوع الإيداع',
            withdrawalFrom: 'لم يتم تحديد عمليات السحب من البنك أو الصندوق'
          }
        }
      }
    }
  },

  // Users Page
  users: {
    title: 'الناس',

    labels: {
      user: 'الشخص'
    },

    gender: {
      man: 'سيدي المحترم',
      woman: 'آنسة',
      company: 'شركة'
    },

    types: {
      man: 'السيد',
      woman: 'آنسة',
      company: 'شركة'
    },

    characters: {
      shareholder: 'شريك',
      employee: 'الموظف',
      intermediary: 'وسيط',
      supplier: 'المورد',
      customer: 'عميل'
    },

    list: {
      title: 'قائمة الأشخاص',

      active: 'نشيط',
      customer: 'عميل',
      presence: 'حضور',

      table: {
        header: {
          row: 'صف',
          select: 'اختيار',
          registryDate: 'تاريخ التسجيل',
          phoneNumber: 'متحرك',
          gender: 'جنس',
          name: 'الاسم الأول واسم العائلة',
          score: 'نتيجة',
          group: 'مجموعة',
          company: 'شركة',
          accessGroup: 'مجموعة المستخدمين',
          maxDebt: 'سقف الديون',
          balance: 'محفظة نقود',
          address: 'عناوين',
          access: 'وصول',
          status: 'شرط',
          shops: 'المخازن'
        }
      }
    },

    trash: {

      title: 'سلة المهملات',

      table: {
        header: {
          restoreAction: 'يعيد',
          deleteAction: 'الحذف الدائم',
          address: 'عناوين',
          maxDebt: 'سقف الديون',
          balance: 'محفظة نقود',
          phoneNumber: 'رقم الهاتف المحمول',
          name: 'الاسم الأول واسم العائلة',
          registryDate: 'تاريخ التسجيل',
          row: 'صف'
        }
      },

      confirmations: {
        deleteUser: {
          title: 'تأكيد حذف المستخدم',
          body: '{userName}هل أنت متأكد أنك تريد حذفه نهائيًا؟'
        }
      }
    },

    select: {
      title: 'قائمة اختيار الأشخاص',

      table: {
        header: {
          address: 'عناوين',
          maxDebt: 'سقف الديون',
          balance: 'محفظة نقود',
          accessGroup: 'مجموعة المستخدمين',
          phoneNumber: 'رقم الهاتف المحمول',
          company: 'شركة',
          name: 'الاسم الأول واسم العائلة',
          registryDate: 'تاريخ التسجيل',
          row: 'صف',
          select: 'اختيار'
        }
      }
    },

    openingBalance: {
      type: {
        debtor: 'المدينون للشركة',
        creditor: 'دائني الشركة'
      },

      insert: {
        title: 'أضف الدورة الأولى {type}',

        notifications: {
          debtor: {
            success: {
              title: 'رسالة',
              message: 'تم تسجيل الفترة الأولى للمدينين بنجاح'
            },

            error: {
              title: 'خطأ',
              message: 'ورافق تسجيل أول معلومة عن فترة المستخدمين المدينين خزينة'
            }
          },

          creditor: {
            success: {
              title: 'رسالة',
              message: 'تم تسجيل الفترة الأولى للدائنين بنجاح'
            },

            error: {
              title: 'خطأ',
              message: 'ورافق تسجيل أول معلومة عن فترة المستخدمين الدائنين خزاعة'
            }
          },

          locked: {
            title: 'خطأ',
            message: 'الأول هو فترة القفل'
          },

          isExists: {
            title: 'خطأ',
            message: 'هذه القيمة موجودة في القائمة',
            dynamicMessage: '{name} يوجد في القائمة'
          }
        }
      },

      edit: {
        title: 'الطبعة الأولى من الدورة {user}',

        labels: {
          price: 'السعر',
          delete: 'إزالة الدورة الأولى'
        },

        confirmations: {
          delete: {
            title: 'تأكيد الحذف',
            body: 'هل أنت متأكد أنك تريد حذف دورة الشخص الأول؟'
          }
        },

        notifications: {
          debtor: {
            update: {
              success: {
                title: 'رسالة',
                message: 'تم تحديث الفترة الأولى للمدين المعني بنجاح'
              },

              error: {
                title: 'خطأ',
                message: 'رافق التحديث الأول لفترة المدين خطأ'
              }
            },

            delete: {
              success: {
                title: 'رسالة',
                message: 'أُلغيت الفترة الأولى للمدين المعني بنجاح'
              },

              error: {
                title: 'خطأ',
                message: 'كان الإغفال الأول من مسار المدين مصحوبا بخطأ'
              }
            }
          },

          creditor: {
            update: {
              success: {
                title: 'رسالة',
                message: 'تم تحديث الفترة الأولى للدائن المعني بنجاح'
              },

              error: {
                title: 'خطأ',
                message: 'رافق التحديث الأول لدورة الدائن خطأ'
              }
            },

            delete: {
              success: {
                title: 'رسالة',
                message: 'تمت إزالة مصطلح الشخص الدائن الأول بنجاح'
              },

              error: {
                title: 'خطأ',
                message: 'رافق الإغفال الأول من مسار الدائن خطأ'
              }
            }
          }
        }
      },

      list: {
        table: {
          header: {
            delete: 'حذف',
            price: 'مقدار',
            name: 'الاسم الأول واسم العائلة',
            rowNumber: 'صف'
          }
        }
      }
    },

    insert: {
      title: 'أضف شخصًا جديدًا',

      base: 'القاعدة',

      gender: {
        man: 'سيدي المحترم',
        woman: 'آنسة'
      },

      labels: {
        name: 'اسم',
        family: 'الكنية',
        gender: 'جنس',
        companyName: 'اسم الشركة',
        phoneNumber: 'رقم التليفون',
        email: 'البريد الإلكتروني'
      },

      validators: {
        company: 'لا يمكن أن يكون اسم الشركة أكثر من 50 حرفًا',
        name: 'لا يمكن أن يزيد الاسم عن 30 حرفًا',
        nameIsWrong: 'لم يتم إدخال الاسم بشكل صحيح',
        nameCantEmpty: 'لا يمكن أن يكون الاسم فارغًا',
        family: 'لا يمكن أن يزيد الاسم الأخير عن 50 حرفًا',
        familyIsWrong: 'لم يتم إدخال الاسم الأخير بشكل صحيح',
        familyCantEmpty: 'لا يمكن أن يكون الاسم الأخير فارغًا',
        phoneNumber: 'تنسيق رقم الاتصال الخاطئ',
        phoneNumberCantEmpty: 'أدخل رقم الاتصال',
        email: 'لا يمكن أن يتجاوز البريد الإلكتروني 50 حرفًا',
        emailIsWrong: 'تنسيق البريد الإلكتروني غير صحيح',
        requiredFieldIsEmpty: 'لم يتم إدخال القيم المطلوبة',
        duplicatePhoneNumber: 'رقم الاتصال الذي تم إدخاله في النظام متاح'
      },

      notifications: {
        insert: {
          success: 'تم تسجيل المعلومات بنجاح'
        }
      }
    },

    edit: {
      title: 'تحرير المعلومات ',

      customer: 'عميل',

      dataCategory: {
        generalInfo: 'معلومات عامة',
        accessLevel: 'مستوى الوصول',
        accessGroup: 'مجموعة المستخدمين'
      },

      labels: {
        name: 'اسم',
        family: 'الكنية',
        company: 'شركة',
        phoneNumber: 'رقم التليفون',
        email: 'البريد الإلكتروني',
        deleteUser: 'مسح المستخدم',
        deleteCustomer: 'حذف العميل'
      },

      validators: {
        inputValuesIsWrong: 'المعلومات التي تم إدخالها غير صحيحة'
      },

      useral: {
        confirmations: {
          delete: {
            title: 'تأكيد حذف المستخدم',
            body: ' {name} هل أنت متأكد أنك تريد حذفه مؤقتًا؟'
          }
        },

        notifications: {
          edit: {
            success: 'تم تحديث المعلومات الشخصية بنجاح',
            error: 'خطأ في تحديث المعلومات الشخصية'
          },
          delete: {
            success: 'تم حذف معلومات الشخص',
            error: 'ارتبط حذف المعلومات الشخصية بخطأ'
          }
        }
      },

      woocommerce: {
        customerLogs: 'سجلات العملاء',

        description: {
          noLogExist: 'لا يوجد سجل لهذا الزبون'
        },

        confirmations: {
          delete: {
            title: 'تأكيد',
            body: 'هل أنت متأكد أنك تريد حذف هذا العميل؟ إذا تم التأكيد ، ستتم أيضًا إزالة معلومات العميل من موقع الويب الخاص بك!'
          }
        },

        notifications: {
          edit: {
            success: 'تم تحديث معلومات العميل بنجاح',
            error: 'خطأ في تحديث معلومات العميل'
          },
          delete: {
            success: 'تم حذف معلومات الشخص',
            error: 'تم ربط حذف معلومات العميل بخطأ'
          },
          requestSend: {
            success: 'تم ارسال الطلب'
          }
        }
      }
    },

    profile: {
      title: 'الملف الشخصي للشخص',
      dynamicTitle: 'الملف الشخصي',

      importantData: {
        balance: 'محفظة نقود',
        score: 'نتيجة',
        accessGroup: 'مجموعة المستخدمين'
      },

      setMaxDebtModal: {
        title: 'تحديد الحد الأقصى للديون',
        label: 'الحد الأقصى للديون الشخصية',

        notifications: {
          success: {
            title: 'رسالة',
            message: 'تم تحديث الحد الأقصى للديون الشخصية بنجاح'
          },

          error: {
            title: 'خطأ',
            message: 'حدث خطأ أثناء تحديث الحد الأقصى للديون'
          }
        }
      },

      addressesModal: {
        title: 'عناوين'
      },

      transactionsModal: {
        title: 'دوران'
      },

      actions: {
        setUserMaxDebt: 'تحديد الحد الأقصى لديون المستخدم',
        showUserAddresses: 'قائمة العناوين',
        showUserTransactions: 'دوران',
        insertSaleInvoice: 'بيع للمستخدم',
        insertPurchaseInvoice: 'شراء من المستخدم',
        insertTreasuryReceive: 'وردت من المستخدم',
        insertTreasuryPayment: 'الدفع للمستخدم'
      }
    },

    transactions: {
      title: 'معدل الدوران الشخصي',
      dynamicTitle: 'دوران',

      documentTitle: 'وثيقة دوران الأفراد',

      table: {

        header: {
          docNumber: 'رقم الوثيقة',
          date: 'تاريخ',
          documentation: 'توثيق',
          price: 'مقدار',
          balance: 'جرد'
        }
      }
    },

    addresses: {
      title: 'عناوين الشخصية',
      dynamicTitle: 'عناوين',

      insert: {
        title: 'أضف عنوان جديد',

        labels: {
          name: 'عنوان عنوان',
          province: 'حالة',
          city: 'مدينة',
          address: 'العنوان البريدي',
          postalCode: 'الكود البريدى',

          receiver: {
            boxTitle: 'معلومات المستلم',
            name: 'اسم المستلم',
            lastName: 'لقب المستلم',
            phoneNumber: 'هوية المتصل'
          }
        },

        notifications: {
          incorrectDate: {
            title: 'رسالة',
            message: 'لم يتم إدخال المعلومات بشكل صحيح'
          },

          success: {
            title: 'رسالة',
            message: 'تم تسجيل العنوان الجديد للمستخدم بنجاح'
          },

          error: {
            title: 'خطأ',
            message: 'ارتبطت إضافة عنوان مستخدم جديد بخطأ'
          }
        }
      },

      list: {

        notFountError: 'لا يوجد عنوان مسجل!'
      },

      select: {

        table: {
          header: {
            receiverPhoneNumber: 'رقم هاتف المستلم',
            receiverLastName: 'لقب المستلم',
            receiverName: 'اسم المستلم',
            postalCode: 'الكود البريدى',
            address: 'عنوان',
            city: 'مدينة',
            province: 'حالة',
            name: 'لقب',
            select: 'اختيار'
          }
        }
      }
    },


    logs: {
      title: 'السجلات الشخصية'
    },

    actions: {
      insert: 'يضيف',
      edit: 'يحرر',
      save: 'سجل',
      delete: 'حذف'
    }
  },

  // documents page
  documents: {
    title: 'مستندات',

    document: {
      title: 'وثيقة',

      table: {

        header: {
          row: 'صف',
          docNumber: 'رقم الوثيقة',
          date: 'تاريخ',
          reference: 'مرجع',
          details: 'تفصیل',
          price: 'مقدار',
          type: 'اكتب'
        }
      }
    }
  },

  //sales
  sales: {
    title: 'تخفيض السعر',

    proformaInvoices: {
      title: 'فاتورة'

    },

    invoices: {
      title: 'فواتير المبيعات',

      labels: {
        id: 'هوية صوتية',
        user: 'جانب الحساب',
        address: 'عنوان',
        registryDate: 'تاريخ التسجيل',
        status: 'شرط',
        store: 'متجر'
      },

      placeholders: {
        id: 'تلقائي'
      },

      prompts: {
        receive: {
          title: 'استلام الأموال من العميل',
          dynamicTitle: 'تلقي أموال من {name}',

          subject: 'رقم فاتورة المبيعات {id}',

          actions: {
            payment: 'الدفع',
            cancel: 'إلغاء وعرض الفاتورة المسبقة'
          }
        },

        address: {
          insert: {
            title: '',
            dynamicTitle: 'إضافة عنوان جديد لـ {name}'
          },

          list: {
            title: '',
            dynamicTitle: 'قائمة العناوين {name}'
          }
        },

        users: {
          list: {
            title: 'قائمة المستخدمين'
          },
          insert: {
            title: 'إضافة مستخدم جديد'
          }
        }
      },

      actionTypes: {
        present: 'فاتورة مبيعات شخصية',
        tel: 'فاتورة مبيعات عبر الهاتف',
        internet: 'فاتورة مبيعات عبر الإنترنت'
      },

      processTypes: {
        proformaInvoices: 'فاتورة ما قبل البيع',
        invoices: 'فاتورة المبيعات',
        storeExit: 'رحيل المستودع'
      },

      notifications: {
        isExists: {
          message: 'هذه القيمة موجودة في القائمة',
          dynamicMessage: '{name} يوجد في القائمة'
        },

        increaseCount: {
          message: 'تم إضافة رقم هذا المنتج',
          dynamicMessage: 'تمت إضافة الرقم {name}'
        },

        productNotFount: 'لم يتم العثور على منتج مع هذا الرمز الشريطي',
        quantity: 'الجرد لا يكفي!',
        lowBalance: 'تم تسجيل الفاتورة المسبقة ، ولكن بسبب نقص ائتمان العميل ، من الضروري استلام الأموال لإكمال الفاتورة!',

        insert: {
          success: 'تم تسجيل الفاتورة بنجاح',
          error: 'تسجيل الفاتورة كان مصحوبا بخطأ!'
        },

        edit: {
          success: 'تم تحرير الفاتورة بنجاح',
          error: 'تحرير الفاتورة رافقه خطأ!'
        },

        delete: {
          success: 'تم حذف الفاتورة بنجاح',
          error: 'كان حذف فاتورة المبيعات مصحوبًا بخطأ'
        },


        parseError: {
          user: 'المورد غير محدد!',
          accountSide: 'جانب الحساب غير محدد!',
          lines: 'لا توجد عناصر محددة!',
          address: 'لم يتم إدخال العنوان بشكل صحيح!',
          storeroom: 'لم يتم اختيار المستودع!',
          unitPrice: 'لم يتم إدخال كمية البضائع!',
          invoiceNumber: 'تم تحديد رقم الفاتورة هذا بالفعل!'
        }
      },

      confirmations: {
        delete: {
          title: 'تأكيد شطب فاتورة المبيعات',
          body: 'إذا تم حذف فاتورة المبيعات ، فسيتم حذف جميع المستندات والتحويلات الخاصة بالمستودع المتعلقة بالفاتورة. هل أنت واثق؟'
        }
      },

      list: {
        title: 'فواتير المبيعات',

        table: {
          header: {
            id: 'رقم الفاتورة',
            receivedId: 'رقم الفاتورة المرجعية',
            creator: 'المنشئ',
            user: 'جانب الحساب',
            date: 'تاریخ',
            finalPrice: 'القيمة النهائية',
            type: 'نوع البيع',
            status: 'شرط'
          },

          filters: {
            type: {
              all: 'الجميع',
              present: 'المبيعات وجهاً لوجه',
              tel: 'مبيعات الهاتف',
              internet: 'البيع عبر الإنترنت'
            },
            status: {
              all: 'الجميع',
              proformaInvoices: 'فاتورة ما قبل البيع',
              invoices: 'فاتورة المبيعات',
              storeExit: 'رحيل المستودع'
            }
          }
        },

        actionTypes: {
          present: 'المبيعات وجهاً لوجه',
          tel: 'مبيعات الهاتف',
          internet: 'البيع عبر الإنترنت'
        }
      },

      insert: {
        title: 'اضافة فاتورة مبيعات',

        telTypeTitle: 'إضافة فاتورة مبيعات الهاتف',
        presentTypeTitle: 'أضف فاتورة مبيعات وجهًا لوجه',

        table: {
          header: {
            rowNumber: 'صف',
            barcode: 'الباركود',
            name: 'کالا',
            variant: 'عامل',
            count: 'رقم',
            unitPrice: 'وحدة المبلغ',
            totalPrice: 'المبلغ الإجمالي',
            discount: 'إجمالي الخصم',
            finalPrice: 'القيمة النهائية',
            storeroom: 'متجر',
            delete: 'حذف'
          }
        }
      },

      invoice: {
        title: 'فاتورة المبيعات',
        dynamicTitle: 'فاتورة المبيعات {type} رقم {id}',
        dynamicTitleWithoutId: 'فاتورة مبيعات {type}',

        labels: {
          payment: 'الدفع',
          cancel: 'يلغي'
        },

        table: {
          header: {
            rowNumber: 'صف',
            code: 'الباركود',
            name: 'کالا',
            variant: 'عامل',
            store: 'متجر',
            count: 'رقم',
            unitPrice: 'وحدة المبلغ',
            totalPrice: 'المبلغ الإجمالي',
            discount: 'خصم',
            finalPrice: 'القيمة النهائية'
          }
        },

        validators: {
          user: 'المورد غير محدد!',
          products: 'لا توجد عناصر محددة!',
          store: 'لم يتم اختيار المستودع!',
          unitPrice: 'لم يتم إدخال كمية البضائع!',
          invoiceNumber: 'تم تحديد رقم الفاتورة هذا بالفعل!',
          quantity: 'الجرد لا يكفي!',
          lowPrice: 'تم تسجيل الفاتورة المسبقة ، ولكن بسبب نقص ائتمان العميل ، من الضروري استلام الأموال لإكمال الفاتورة!'
        },

        notifications: {
          complete: {
            success: 'تم تسجيل الفاتورة بنجاح',
            error: 'تسجيل الفاتورة كان مصحوبا بخطأ!'
          },

          storeExit: {
            error: 'فاتورة مغادرة المستودع غير مسجلة بفاتورة المبيعات'
          }
        },

        actionTypes: {
          present: 'حضور',
          tel: 'هاتف',
          internet: 'إنترنت'
        }
      },

      edit: {
        title: 'تحرير فاتورة المبيعات',
        dynamicTitle: 'تحرير فاتورة المبيعات {type}',

        labels: {
          first: 'أولا',
          second: 'ثانيا'
        },

        table: {
          header: {
            rowNumber: 'صف',
            code: 'الباركود',
            name: 'کالا',
            variant: 'عامل',
            store: 'متجر',
            count: 'رقم',
            unitPrice: 'وحدة المبلغ',
            totalPrice: 'المبلغ الإجمالي',
            discount: 'خصم',
            finalPrice: 'القيمة النهائية',
            delete: 'حذف'
          }
        },

        actionTypes: {
          present: 'حضور',
          tel: 'هاتف',
          internet: 'إنترنت'
        }
      }
    },

    returnInvoices: {
      title: 'فواتير مرجعية المبيعات',

      labels: {
        user: 'جانب الحساب',
        id: 'هوية صوتية',
        date: 'تاريخ التسجيل',
        status: 'شرط'
      },

      placeholders: {
        id: 'تلقائي'
      },

      status: {
        preInvoice: 'فاتورة مرجعية للمبيعات',
        invoice: 'فاتورة مرجعية للمبيعات',
        storeReceived: 'ايصالات الايداع'
      },

      actionTypes: {
        present: 'مرجع مبيعات شخصيًا',
        tel: 'مرجع مبيعات الهاتف',
        internet: 'مرجع مبيعات الإنترنت'
      },

      notifications: {
        document: {
          success: 'تم تسجيل مستند الفاتورة بنجاح',
          error: 'رافق تسجيل مستند الفاتورة خطأ',
          isExist: 'تم تسجيل مستند الفاتورة'
        },

        insert: {
          success: 'تم تسجيل فاتورة مرتجعات المبيعات بنجاح',
          error: 'تسجيل فاتورة مرتجعات المبيعات مصحوب بخطأ!'
        },

        edit: {
          success: 'تم تحرير فاتورة مرتجعات المبيعات بنجاح',
          error: 'تحرير فاتورة مرتجعات المبيعات كان مصحوبًا بخطأ!'
        },

        refreshStatus: {
          success: 'تم إرجاع حالة الفاتورة بنجاح',
          error: 'تم إرجاع حالة الفاتورة مع وجود خطأ'
        },

        parseError: {
          user: 'المورد غير محدد!',
          lines: 'لا توجد عناصر محددة!',
          store: 'لم يتم اختيار المستودع!',
          unitPrice: 'لم يتم إدخال كمية البضائع!',
          invoiceNumber: 'تم تحديد رقم الفاتورة هذا بالفعل!',
          quantity: 'الجرد لا يكفي!'
        }
      },

      list: {
        title: 'فواتير مرجعية المبيعات',

        table: {
          header: {
            id: 'رقم الفاتورة',
            creator: 'المنشئ',
            user: 'جانب الحساب',
            date: 'تاریخ',
            totalPrice: 'المبلغ الإجمالي',
            finalPrice: 'القيمة النهائية',
            type: 'نوع البيع',
            status: 'شرط'
          },

          filters: {
            type: {
              all: 'الجميع',
              present: 'المبيعات وجهاً لوجه',
              tel: 'مبيعات الهاتف',
              internet: 'البيع عبر الإنترنت'
            }
          }
        }
      },

      insert: {
        title: 'إضافة فاتورة مرجعية المبيعات',

        typeTitle: {
          present: 'أضف الفاتورة المرجعية للمبيعات وجهًا لوجه',
          tel: 'إضافة فاتورة مرجعية للمبيعات الهاتفية',
          internet: 'أضف فاتورة مرجعية للمبيعات عبر الإنترنت'
        },

        table: {
          header: {
            rowNumber: 'صف',
            barcode: 'الباركود',
            name: 'کالا',
            variant: 'عامل',
            count: 'رقم',
            unitPrice: 'وحدة المبلغ',
            totalPrice: 'المبلغ الإجمالي',
            discount: 'إجمالي الخصم',
            finalPrice: 'القيمة النهائية',
            store: 'متجر',
            delete: 'حذف'
          }
        }
      },

      invoice: {
        title: 'فاتورة مرجعية للمبيعات',

        table: {
          header: {
            rowNumber: 'صف',
            code: 'الباركود',
            name: 'کالا',
            variant: 'عامل',
            store: 'متجر',
            count: 'رقم',
            unitPrice: 'وحدة المبلغ',
            totalPrice: 'المبلغ الإجمالي'
          }
        }
      },

      edit: {
        title: 'تحرير فاتورة مرجع المبيعات',

        typeTitle: {
          present: 'تحرير الفاتورة المرجعية للمبيعات وجهًا لوجه',
          tel: 'تحرير فاتورة مرجع مبيعات الهاتف',
          internet: 'تحرير الفاتورة المرجعية للمبيعات عبر الإنترنت'
        },

        table: {
          header: {
            rowNumber: 'صف',
            barcode: 'الباركود',
            name: 'کالا',
            variant: 'عامل',
            count: 'رقم',
            unitPrice: 'وحدة المبلغ',
            totalPrice: 'المبلغ الإجمالي',
            discount: 'إجمالي الخصم',
            finalPrice: 'القيمة النهائية',
            store: 'متجر',
            delete: 'حذف'
          }
        }
      },

      refreshStatus: {
        title: 'إرجاع حالة الفاتورة المرجعية للمبيعات',

        labels: {
          description: 'ضع في اعتبارك أنه إذا قمت بإرجاع حالة الفاتورة ، فسيتم حذف جميع المستندات والتحويلات المتعلقة بكل حالة.',
          accept: 'تأكيد',
          cancel: 'يلغي'
        }
      }
    },

    groups: {
      title: 'مجموعات المبيعات',


      list: {
        title: 'قائمة مجموعات المبيعات'
      },

      insert: {
        title: 'أضف مجموعة مبيعات جديدة'
      },

      edit: {
        title: 'تحرير مجموعة المبيعات'
      }
    },

    pricing: {
      title: 'التسعير',

      table: {

        header: {
          row: 'صف',
          code: 'الشفرة',
          name: 'اسم',
          dynamic: 'عامل',
          amount: 'مقدار',
          date: 'تاريخ التسجيل',
          action: 'العملية'
        },

        content: {

          button: {
            set: 'سجل'
          }
        }
      }
    },

    discount: {
      title: 'تخفيض المبيعات'

    },

    actions: {
      insert: 'يضيف',
      edit: 'تعديل',
      filters: 'المرشحات'
    }
  },

  //purchases
  purchases: {
    title: 'يشتري',

    labels: {
      id: 'المورد',
      user: 'هوية صوتية',
      date: 'تاريخ التسجيل',
      status: 'حالة'
    },

    placeholders: {
      id: 'تلقائي'
    },

    table: {
      header: {
        id: 'رقم الفاتورة',
        creator: 'المنشئ',
        user: 'المورد',
        date: 'تاریخ',
        finalPrice: 'القيمة النهائية',
        status: 'حالة',
        delete: 'حذف'
      }
    },

    lines: {
      table: {
        header: {
          delete: 'حذف',
          storeroom: 'متجر',

          finalPrice: 'القيمة النهائية',
          totalPrice: 'المبلغ الإجمالي',
          unitPrice: 'وحدة المبلغ',

          discount: 'إجمالي الخصم',
          count: 'عدد',
          variant: 'عامل',
          name: 'کالا',
          barcode: 'الباركود',
          row: 'صف'
        }
      }
    },

    invoices: {
      title: 'فواتير الشراء',

      status: {
        all: 'الجميع',
        preInvoice: 'فاتورة الشراء المسبق',
        invoice: 'فاتورة الشراء',
        receipt: 'ايصالات الايداع'
      },

      notifications: {
        isExists: {
          message: 'تعداد این کالا اضافه شد',
          dynamicMessage: '{product} در لیست وجود دارد'
        },
        increaseCount: {
          message: 'تم إضافة رقم هذا المنتج',
          dynamicMessage: 'يوجد في القائمة {product} '
        },
        productNotFount: 'لا يوجد منتج مع هذا الرمز الشريطي',

        insert: {
          success: 'تم تسجيل الفاتورة بنجاح',
          error: 'تسجيل الفاتورة كان مصحوبا بخطأ!'
        },

        edit: {
          success: 'تم تحديث الفاتورة بنجاح',
          error: 'تحديث الفاتورة مع وجود خطأ!'
        },

        refreshStatus: {
          success: 'تم إرجاع حالة الفاتورة بنجاح',
          error: 'ارتبط عكس حالة الفاتورة بخطأ'
        },

        parseError: {
          user: 'المورد غير محدد!',
          lines: 'لا توجد عناصر محددة!',
          storeroom: 'لم يتم اختيار المستودع!',
          unitPrice: 'لم يتم إدخال كمية البضائع!',
          invoiceNumber: 'تم تحديد رقم الفاتورة هذا بالفعل!'
        }
      },

      list: {
        title: 'فواتير الشراء'
      },

      insert: {
        title: 'إضافة فاتورة الشراء',

        table: {

          header: {
            row: 'صف',
            code: 'الشفرة',
            product: 'کالا',
            dynamic: 'عامل',
            number: 'عدد',
            unitAmount: 'وحدة المبلغ',
            amount: 'المبلغ الإجمالي',
            store: 'متجر',
            action: '#'
          }
        }
      },

      invoice: {
        title: 'فاتورة الشراء',
        dynamicTitle: 'رقم شراء الفاتورة {invoiceId}',

        notifications: {
          insertReturnInvoiceError: 'إيصال مستودع التحويلات فاتورة الشراء غير مسجلة'
        }
      },

      edit: {
        title: 'تحرير فاتورة الشراء',
        dynamicTitle: 'تحرير رقم فاتورة الشراء {invoiceId}'
      },

      refreshStatus: {
        title: 'إرجاع حالة فاتورة الشراء',

        labels: {
          description: 'ضع في اعتبارك أنه إذا قمت بإرجاع حالة الفاتورة ، فسيتم حذف جميع المستندات والتحويلات المتعلقة بكل حالة.',
          accept: 'تأكيد',
          cancel: 'يلغي'
        }
      }
    },

    returnInvoices: {
      title: 'فواتير مرجعية الشراء',


      status: {
        all: 'الجميع',
        preInvoice: 'مرجع فاتورة الشراء المسبق',
        invoice: 'فاتورة الشراء',
        receipt: 'رحيل المستودع'
      },

      notifications: {
        noProductSelected: 'لم يتم تحديد عناصر',
        productsNotExisted: 'جرد البضائع المختارة لا يكفي',

        isExists: {
          message: 'تم إضافة رقم هذا المنتج',
          dynamicMessage: '{product} يوجد في القائمة'
        },
        increaseCount: {
          message: 'تم إضافة رقم هذا المنتج',
          dynamicMessage: 'تمت إضافة الرقم {product} '
        },
        productNotFount: 'لم يتم العثور على منتج مع هذا الرمز الشريطي',

        insert: {
          success: 'تم تسجيل الفاتورة المرجعية للشراء بنجاح',
          error: 'كان تسجيل فاتورة مرجع الشراء مصحوبًا بخطأ'
        },

        edit: {
          success: 'تم تحديث فاتورة مرجع الشراء بنجاح',
          error: 'كان تحديث فاتورة مرجع الشراء مصحوبًا بخطأ'
        },

        refreshStatus: {
          success: 'تم إرجاع حالة فاتورة الشراء بنجاح',
          error: 'رافق إعادة حالة الفاتورة المرجعية للشراء خطأ'
        },

        parseError: {
          user: 'المورد غير محدد!',
          lines: 'لا توجد عناصر محددة!',
          storeroom: 'لم يتم اختيار المستودع!',
          unitPrice: 'لم يتم إدخال كمية البضائع!',
          invoiceNumber: 'تم تحديد رقم الفاتورة هذا بالفعل!'
        }
      },

      list: {
        title: 'فواتير مرجعية الشراء',

        table: {

          header: {
            number: 'رقم الفاتورة',
            creator: 'المنشئ',
            user: 'جانب الحساب',
            date: 'تاريخ الخلق',
            totalPrice: 'المبلغ الإجمالي',
            status: 'حالة'
          }
        }
      },

      insert: {
        title: 'إضافة فاتورة شراء مرجعية',

        table: {

          header: {
            row: 'صف',
            code: 'الشفرة',
            product: 'کالا',
            dynamic: 'عامل',
            number: 'عدد',
            unitAmount: 'وحدة المبلغ',
            amount: 'المبلغ الإجمالي',
            store: 'متجر',
            action: '#'
          }
        }
      },

      invoice: {
        title: 'فاتورة شراء مرجعية'
      },

      edit: {
        title: 'تحرير فاتورة مرجعية الشراء',

        dynamicTitle: 'تحرير الرقم المرجعي لفاتورة الشراء {invoiceId}'
      },


      refreshStatus: {
        title: 'إرجاع حالة فاتورة مرجعية الشراء',

        labels: {
          description: 'ضع في اعتبارك أنه إذا قمت بإرجاع حالة الفاتورة ، فسيتم حذف جميع المستندات والتحويلات المتعلقة بكل حالة.',
          accept: 'تأكيد',
          cancel: 'يلغي'
        }
      }
    },

    actions: {
      insert: 'يضيف',
      edit: 'تعديل',
      filters: 'المرشحات'
    }
  },

  //treasury
  treasury: {
    title: 'خزينة',

    banks: {
      title: 'البنوك',

      insert: {
        title: 'أضف مصرفًا',

        notifications: {
          insert: {
            success: 'تم تسجيل المعلومات المصرفية الجديدة بنجاح',
            error: 'كان تسجيل المعلومات المصرفية الجديدة مصحوبًا بخطأ'
          }
        }
      },

      list: {
        title: 'البنوك'
      },

      trash: {
        title: 'قمامة البنوك',

        confirmations: {
          delete: {
            title: 'تأكيد حذف البنك',
            body: ' {bank} هل أنت متأكد أنك تريد حذف البنك نهائيا؟'
          }
        },

        notifications: {
          restore: {
            success: 'تمت استعادة المعلومات المصرفية بنجاح',
            error: 'كان استرداد المعلومات المصرفية مصحوبًا بخطأ'
          },

          delete: {
            success: 'نجح الحذف الدائم للمعلومات المصرفية',
            error: 'كان الحذف الدائم للمعلومات المصرفية مصحوبًا بخطأ'
          }
        }
      },

      edit: {
        title: 'تحرير البنك',
        dynamicTitle: 'تحرير البنك {bank}',

        confirmations: {
          delete: {
            title: 'تأكيد حذف البنك',
            body: 'هل أنت متأكد من الإزالة المؤقتة للبنك؟ {bank} '
          }
        },

        notifications: {
          edit: {
            success: 'تم تحرير المعلومات المصرفية بنجاح',
            error: 'كان تحرير المعلومات المصرفية مصحوبًا بخطأ'
          },

          delete: {
            success: 'تم حذف المعلومات المصرفية بنجاح',
            error: 'كان حذف المعلومات المصرفية مصحوبًا بخطأ'
          }
        }
      },

      bank: {
        title: 'بنك',
        dynamicTitle: 'بنك {bank}',

        importantData: {
          balance: 'جرد',
          openingBalance: 'الدورة الأولى',
          transactionsCount: 'عدد المعاملات'
        },

        actions: {
          transactions: 'دوران البنك'
        }
      },

      transactions: {
        title: 'دوران البنك',
        dynamicTitle: 'دوران البنك {bank}',

        table: {
          header: {
            balance: 'جرد',
            price: 'مقدار',
            description: 'توثيق',
            date: 'تاریخ',
            docNumber: 'رقم الوثيقة'
          }
        }
      },

      openingBalance: {
        title: '',

        table: {
          header: {
            row: 'صف',
            name: 'اسم البنك',
            price: 'مقدار',
            delete: 'حذف'
          }
        },

        notifications: {
          locked: 'الأول هو فترة القفل',

          isExisted: {
            message: 'هذه القيمة موجودة في القائمة',
            dynamicMessage: 'هناك بنوك في القائمة {bank} '
          },

          insert: {
            success: 'تم تسجيل الفترة الأولى للبنك بنجاح',
            error: 'رافق التسجيل الأول لفترة البنك خطأ'
          },

          edit: {
            success: 'تم تحديث الفترة الأولى للبنك بنجاح',
            error: 'كان التحديث الأول للبنك المعني مصحوبًا بخطأ'
          },

          delete: {
            success: 'تم الانتهاء بنجاح من الإزالة الأولى للبنك المعني',
            error: 'كان الحذف الأول للبنك المعني مصحوبًا بخطأ'
          }
        },

        insert: {
          title: 'أول إضافة إلى الدورة المصرفية'
        },

        edit: {
          title: 'النسخة الأولى من الدورة المصرفية {bank}',

          confirmations: {
            delete: {
              title: 'تأكيد الحذف',
              body: 'هل أنت متأكد أنك تريد حذف أول مصطلح مصرفي؟'
            }
          },

          labels: {
            price: 'مقدار',
            delete: 'إزالة الدورة الأولى'
          }
        }
      },

      table: {
        header: {
          select: 'اختيار',
          row: 'صف',
          name: 'اسم',
          cardNumber: 'رقم البطاقة',
          accountNumber: 'رقم حساب',
          balance: 'جرد',
          delete: 'حذف',
          restore: 'يعيد'
        }
      },

      labels: {
        name: 'اسم البنك',
        accountNumber: 'رقم حساب',
        cardNumber: 'رقم البطاقة',
        shebaNumber: 'رقم شبعا',
        delete: 'قم بإزالة البنك'
      },

      validators: {
        name: 'لم يتم إدخال اسم البنك بشكل صحيح',
        accountNumber: 'لم يتم إدخال رقم الحساب بشكل صحيح',
        cardNumber: 'لم يتم إدخال رقم البطاقة بشكل صحيح',
        shebaNumber: 'لم يتم إدخال رقم شيبا بشكل صحيح'
      }
    },

    paymentGateways: {
      title: 'بوابات الدفع',

      labels: {
        status: 'حالة',
        type: 'نوع',
        bank: 'بنك التسوية',
        title: 'عنوان المنفذ',
        endTime: 'نهاية يوم عمل الميناء',
        merchantCode: 'كود القبول',
        terminalCode: 'كود المحطة',
        description: 'وصف'
      },

      table: {
        header: {
          restore: 'يعيد',
          delete: 'حذف',
          balance: 'جرد',
          bank: 'بنك التسوية',
          status: 'حالة',
          title: 'عنوان المنفذ',
          type: 'نوع المنفذ',
          row: 'صف'
        }
      },

      types: {
        all: 'الجميع',
        pos: 'قارئ بطاقة',
        online: 'بوابة الدفع عبر الإنترنت'
      },

      status: {
        all: 'الجميع',
        enable: 'نشيط',
        disable: 'غير نشط'
      },

      confirmations: {
        delete: {
          title: 'تأكيد إزالة بوابة الدفع',
          body: ' {paymentGateway} هل أنت متأكد أنك تريد حذفه نهائيًا؟'
        }
      },

      notifications: {
        incorrectData: 'لم يتم إدخال المعلومات بشكل صحيح',

        insert: {
          success: 'تم إنشاء بوابة الدفع بنجاح',
          error: 'حدث خطأ في إنشاء بوابة الدفع'
        },

        edit: {
          success: 'تم تحديث بوابة الدفع بنجاح',
          error: 'واجه تحديث بوابة الدفع خطأ'
        },

        delete: {
          success: 'تمت إزالة بوابة الدفع بنجاح',
          error: 'رافق حذف بوابة الدفع خطأ'
        },

        restore: {
          success: 'تمت استعادة بوابة الدفع بنجاح',
          error: 'حدث خطأ في إعادة بوابة الدفع'
        }
      },

      insert: {
        title: 'إضافة بوابة الدفع',
        suggestTitle: '{type} بنك {bank}'
      },

      list: {
        title: 'بوابات الدفع'
      },

      trash: {
        title: 'بوابات القمامة'
      },

      edit: {
        title: 'تحرير بوابة الدفع',
        dynamicTitle: 'تحرير {paymentGateway}'
      },

      paymentGateway: {
        title: 'بوابة الدفع',
        dynamicTitle: '{paymentGateway}',

        importantData: {
          type: 'نوع',
          bank: 'بنك التسوية',
          balance: 'جرد'
        },

        actions: {
          transactions: 'دوران',
          receive: 'إيصال'
        }
      },

      transactions: {
        title: 'معدل دوران بوابة الدفع',
        dynamicTitle: 'دوران {paymentGateway}',

        table: {
          header: {
            balance: 'جرد',
            price: 'مقدار',
            description: 'توثيق',
            date: 'تاریخ',
            docNumber: 'رقم الوثيقة'
          }
        }
      },

      receive: {
        title: 'إشعار باستلام بوابة الدفع',
        dynamicTitle: 'إيصال {paymentGateway}',

        labels: {
          receivedBalance: 'تم استلام المبلغ',
          receivedBalanceTransferId: 'تتبع القضية',
          totalBalance: 'إجمالي المخزون المستحق',
          receivedYesterdayBalance: 'استقبل أمس'
        },

        notifications: {
          balanceIsNotEnough: 'المبلغ الذي تم إدخاله أكبر من رصيد المنفذ',
          incorrectPriceOrId: 'لم يتم إدخال المبلغ أو رقم التتبع بشكل صحيح',

          receive: {
            success: 'تم إخطار الإيصال بنجاح',
            error: 'كان إشعار الاستلام مصحوبًا بخطأ'
          }
        }
      }
    },

    cashBoxes: {
      title: 'مربعات',

      labels: {
        name: 'اسم الصندوق',
        delete: 'احذف المربع'
      },

      confirmations: {
        delete: {
          title: 'تأكيد حذف المربع',
          body: ' {cashBox} هل أنت متأكد أنك تريد حذف الصندوق مؤقتًا؟'
        },
        forceDelete: {
          title: 'تأكيد حذف المربع',
          body: 'هل أنت متأكد أنك تريد حذف الصندوق نهائيًا؟ {cashBox} '
        }
      },

      notifications: {
        insert: {
          success: 'تم إنشاء معلومات الصندوق الجديد بنجاح',
          error: 'وكان تسجيل معلومات الصندوق الجديد مصحوبا بخطأ'
        },

        edit: {
          success: 'تم تحديث معلومات الصندوق بنجاح',
          error: 'رافق تحديث معلومات الصندوق الجديد خطأ'
        },

        delete: {
          success: 'تم حذف معلومات الصندوق بنجاح',
          error: 'ارتبط حذف معلومات الصندوق الجديدة بخطأ'
        },

        restore: {
          success: 'تمت استعادة معلومات الصندوق بنجاح',
          error: 'كان استرداد معلومات الصندوق الجديد مصحوبًا بخطأ'
        }
      },

      table: {
        header: {
          select: 'اختيار',
          row: 'صف',
          name: 'اسم',
          balance: 'جرد',
          delete: 'حذف',
          restore: 'يعيد'
        }
      },

      list: {
        title: 'مربعات'
      },

      trash: {
        title: 'سلة المهملات'
      },

      insert: {
        title: 'إضافة صندوق الوارد'
      },

      edit: {
        title: 'مربع التحرير',
        dynamicTitle: 'مربع التحرير {cashBox}'
      },

      cashBox: {
        title: 'مكتب النقدية',
        dynamicTitle: 'مكتب النقدية {cashBox}',

        importantData: {
          balance: 'جرد',
          openingBalance: 'الدورة الأولى',
          transactionsCount: 'عدد المعاملات'
        },

        actions: {
          transactions: 'دوران الصندوق'
        }
      },

      transactions: {
        title: 'معدل دوران الصندوق',
        dynamicTitle: 'معدل دوران الصندوق {cashBox}',

        table: {
          header: {
            balance: 'جرد',
            price: 'مقدار',
            description: 'توثيق',
            date: 'تاریخ',
            docNumber: 'رقم الوثيقة'
          }
        }
      },

      openingBalance: {

        labels: {
          price: 'مقدار',
          delete: 'إزالة الدورة الأولى'
        },

        confirmations: {
          delete: {
            title: 'تأكيد الحذف',
            body: 'هل أنت متأكد من الإزالة الأولى لفترة التمويل المطلوبة؟'
          }
        },

        notifications: {
          locked: 'الأول هو فترة القفل',

          isExisted: {
            message: 'هذه القيمة موجودة في القائمة',
            dynamicMessage: ' {cashBox} هناك مربع في القائمة'
          },

          insert: {
            success: 'تم تسجيل الفترة الأولى للصندوق المطلوب بنجاح',
            error: 'رافق التسجيل الأول لفترة الصندوق المطلوبة خطأ'
          },

          edit: {
            success: 'تم تحديث الفترة الأولى للصندوق بنجاح',
            error: 'كان التحديث الأول لفترة الصندوق المطلوبة مصحوبًا بخطأ'
          },

          delete: {
            success: 'تمت إزالة الفترة الأولى من الصندوق المطلوب بنجاح',
            error: 'رافق الحذف الأول لفترة الصندوق المطلوبة خطأ'
          }
        },

        table: {
          header: {
            delete: 'حذف',
            price: 'مقدار',
            name: 'اسم الصندوق',
            row: 'صف'
          }
        },

        insert: {
          title: 'أضف دورة التمويل الأولى'
        },

        edit: {
          title: 'النسخة الأولى من دورة الصندوق {cashBox}'
        }
      }
    },

    cost: {
      title: 'التكاليف',

      paymentTypes: {
        cash: 'تلقي النقد',
        bank: 'استلام البطاقة بالبطاقة أو بالليل',
        paymentGateway: 'احصل على وقفة أو بوابة'
      },

      bankTypes: {
        pos: 'POS',
        card: 'بطاقة إلى بطاقة',
        sheba: 'شبا'
      },

      types: {
        cash: 'مكتب النقدية',
        bank: 'بنك',
        user: '',
        storeroom: 'متجر',
        paymentGateway: 'بوابة الدفع'
      },

      labels: {
        account: 'عنوان التكلفة',
        cash: 'الدفع من الصندوق',
        bank: 'الدفع من البنك',
        paymentType: 'نوع الدفع',
        price: 'مقدار',
        description: 'وصف'
      },

      table: {
        header: {
          price: 'مقدار',
          account: 'عنوان التكلفة',
          source: 'دافع',
          creator: 'المنشئ',
          date: 'تاریخ',
          docNumber: 'رقم الوثيقة'
        }
      },

      insert: {
        title: 'تكلفة جديدة',

        notifications: {
          invalidAccount: 'عنوان التكلفة غير محدد',
          invalidCash: 'المربع غير محدد',
          invalidBank: 'لم يتم اختيار البنك',
          invalidPrice: 'لم يتم إدخال المبلغ',
          invalidPaymentType: 'لم يتم تحديد نوع الدفع',
          invalidDescription: 'لم يتم إدخال وصف المصاريف',

          insert: {
            success: 'تم إنشاء مستند المصروفات بنجاح',
            error: 'ارتبط إنشاء مستند تكلفة بخطأ'
          }
        },

        suggests: {
          description: 'احصل من {user} عن {subject}'
        }
      },

      list: {
        title: 'قائمة المصاريف'
      },

      document: {
        title: 'وثيقة المصاريف',
        dynamicTitle: 'رقم مستند المصاريف {docNumber}',

        confirmations: {
          delete: {
            title: 'قم بتأكيد حذف المستند',
            body: 'هل أنت متأكد أنك تريد حذف هذا المستند بالكامل؟'
          }
        },

        notifications: {
          delete: {
            success: 'تم حذف معلومات مستند المصاريف بنجاح',
            error: 'ارتبط حذف معلومات مستند التكلفة بخطأ'
          }
        },

        labels: {
          sources: 'الدافع / دافعي',
          account: 'عنوان التكلفة',
          details: 'بقية المعلومات',
          creator: 'المنشئ',
          date: 'تاريخ التسجيل',
          description: 'وصف'
        }
      }
    },

    payment: {
      title: 'المدفوعات',

      bankTypes: {
        pos: 'POS',
        card: 'بطاقة إلى بطاقة',
        sheba: 'شبا'
      },

      types: {
        cash: 'مكتب النقدية',
        bank: 'بنك',
        user: '',
        storeroom: 'متجر',
        paymentGateway: 'بوابة الدفع'
      },

      labels: {
        paymentType: 'نوع الدفع',
        user: 'دفع ل',
        source: 'الدفع من',
        price: 'مقدار',
        description: 'وصف',
        transactionsReferenceId: 'تتبع القضية'
      },

      table: {
        header: {
          price: 'مقدار',
          destination: 'المتلقي',
          source: 'دافع',
          description: 'توثيق',
          creator: 'المنشئ',
          date: 'تاریخ',
          docNumber: 'رقم الوثيقة'
        }
      },

      insert: {
        title: 'دفعة جديدة',

        notifications: {
          invalidUser: 'لم يتم اختيار الشخص',
          invalidPrice: 'لم يتم إدخال المبلغ',
          invalidPaymentFrom: 'لم يتم تحديد مصدر الدفع',
          invalidPaymentType: 'لم يتم تحديد نوع الدفع',
          invalidDescription: 'لم يتم إدخال وصف الدفع',

          insert: {
            success: 'تم إنشاء مستند الدفع بنجاح',
            error: 'تم إنشاء مستند الدفع مع وجود خطأ'
          }
        },

        suggests: {
          description: 'احصل من {user} عن {subject}'
        }
      },

      list: {
        title: 'قائمة الدفع'
      },

      document: {
        title: 'وثيقة دفع',
        dynamicTitle: 'رقم مستند الدفع {docNumber}',

        confirmations: {
          delete: {
            title: 'قم بتأكيد حذف المستند',
            body: 'هل أنت متأكد أنك تريد حذف هذا المستند بالكامل؟'
          }
        },

        notifications: {
          delete: {
            success: 'تم حذف معلومات مستند الدفع بنجاح',
            error: 'كان حذف معلومات مستند الدفع مصحوبًا بخطأ'
          }
        },

        labels: {
          sources: 'الدافع / دافعي',
          destinations: 'المستلم / المستلمون',
          details: 'بقية المعلومات',
          creator: 'المنشئ',
          date: 'تاريخ التسجيل',
          description: 'وصف'
        }
      }
    },

    receive: {
      title: 'التحميلات',

      receiveTypes: {
        cash: 'تلقي النقد',
        bank: 'استلام البطاقة بالبطاقة أو بالليل',
        paymentGateway: 'احصل على وقفة أو بوابة'
      },

      types: {
        cash: 'مكتب النقدية',
        bank: 'بنك',
        user: '',
        storeroom: 'متجر',
        paymentGateway: 'بوابة الدفع'
      },

      labels: {
        receiveType: 'نوع الإيصال',
        user: 'احصل من',
        cash: 'الإيداع في الصندوق',
        bank: 'الإيداع في البنك',
        paymentGateway: 'الإيداع في الميناء',
        price: 'مقدار',
        description: 'وصف',
        transactionsReferenceId: 'تتبع القضية'
      },

      table: {
        header: {
          price: 'مقدار',
          destination: 'المتلقي',
          source: 'دافع',
          description: 'توثيق',
          creator: 'المنشئ',
          date: 'تاریخ',
          docNumber: 'رقم الوثيقة'
        }
      },

      insert: {
        title: 'الحصول على الجديد',

        notifications: {
          invalidUser: 'لم يتم اختيار الشخص',
          invalidPrice: 'لم يتم إدخال المبلغ',
          lessPrice: 'يجب ألا يقل المبلغ الذي تم إدخاله عن {price} {currency} ',
          invalidDescription: 'لم يتم إدخال وصف الإيصال',
          invalidTransactionsReferenceId: 'تم إدخال رقم تتبع خاطئ',
          invalidCash: 'المربع غير محدد',
          invalidBank: 'لم يتم اختيار البنك',
          invalidPaymentGateway: 'المنفذ غير محدد',

          insert: {
            success: 'تم إنشاء مستند الإيصال بنجاح',
            error: 'ارتبط إنشاء مستند تنزيل بخطأ'
          }
        },

        suggests: {
          description: 'احصل من {user} عن {subject}'
        }
      },

      list: {
        title: 'قائمة التنزيل'
      },

      document: {
        title: 'وثيقة الاستلام',
        dynamicTitle: 'رقم مستند الاستلام {docNumber}',

        confirmations: {
          delete: {
            title: 'قم بتأكيد حذف المستند',
            body: 'هل أنت متأكد أنك تريد حذف هذا المستند بالكامل؟'
          }
        },

        notifications: {
          delete: {
            success: 'تم حذف معلومات المستند المتلقاة بنجاح',
            error: 'كان حذف معلومات المستند المستلمة مصحوبًا بخطأ'
          }
        },

        labels: {
          sources: 'الدافع / دافعي',
          destinations: 'المستلم / المستلمون',
          details: 'بقية المعلومات',
          creator: 'المنشئ',
          date: 'تاريخ التسجيل',
          description: 'وصف'
        }
      }
    },

    internalFundsTransfer: {
      title: 'تحويل الخزينة',

      types: {
        cash: 'مكتب النقدية',
        bank: 'بنك',
        user: '',
        storeroom: 'متجر',
        paymentGateway: 'بوابة الدفع'
      },

      labels: {
        sourceType: 'يأخذ من',
        source: '{type} الأصل',
        destinationType: 'الإيداع',
        destination: '{type} الوجهة',
        price: 'مقدار'
      },

      table: {
        header: {
          price: 'مقدار',
          description: 'توثيق',
          creator: 'المنشئ',
          date: 'تاریخ',
          docNumber: 'رقم الوثيقة'
        }
      },

      insert: {
        title: 'تحويل الخزينة الجديد',

        notifications: {
          invalidPrice: 'لم يتم إدخال المبلغ',
          invalidSourceType: 'نوع المصدر غير محدد',
          invalidSource: 'المصدر غير محدد',
          invalidDestinationType: 'نوع الوجهة غير محدد',
          invalidDestination: 'لم يتم تحديد الوجهة',
          equalSourceAndDestination: 'لا يمكن أن يكون الأصل والوجهة متماثلين',

          insert: {
            success: 'تم إنشاء مستند التحويل الداخلي بنجاح',
            error: 'تم إنشاء مستند تحويل أموال داخلي مع وجود خطأ'
          }
        }
      },

      list: {
        title: 'قائمة تحويل الخزينة'
      },

      document: {
        title: 'مستند تحويل الخزينة',
        dynamicTitle: 'رقم مستند تحويل الخزانة {docNumber}',

        confirmations: {
          delete: {
            title: 'قم بتأكيد حذف المستند',
            body: 'هل أنت متأكد أنك تريد حذف هذا المستند بالكامل؟'
          }
        },

        notifications: {
          delete: {
            success: 'تم حذف معلومات مستند تحويل الخزينة بنجاح',
            error: 'ارتبط حذف معلومات مستند تحويل الخزينة بخطأ'
          }
        },

        labels: {
          sources: 'الدافع / دافعي',
          destinations: 'المستلم / المستلمون',
          details: 'بقية المعلومات',
          creator: 'المنشئ',
          date: 'تاريخ التسجيل',
          description: 'وصف'
        }
      }
    },

    transactions: {
      title: 'المعاملات المالية',

      list: {
        title: 'المعاملات المالية'
      },

      insert: {
        title: 'أضف معاملة'
      },

      transaction: {
        title: 'عملية تجارية'
      }
    }
  },

  //reports
  reports: {
    title: 'التقارير',


    logs: {
      title: 'السجلات',

      table: {
        header: {
          rowNumber: 'صف',
          createdAt: 'سجل التاريخ',
          userName: 'المستعمل',
          description: 'وصف',
          details: 'تفاصيل'
        }
      },

      dialogs: {
        title: 'تفاصيل السجل',
        withoutDescription: 'لا مزيد من التوضيح'
      },

      list: {
        title: 'قائمة السجلات'
      }
    },

    usersTotalSales: {
      title: 'إجمالي المبيعات للأفراد',


      table: {
        header: {
          row: 'صف',
          name: 'الاسم الأول واسم العائلة',
          company: 'شركة',
          phoneNumber: 'رقم التليفون',
          totalSales: 'إجمالي المبيعات'
        }
      }
    }
  },

  //events

  events: {
    title: 'رسائل',

    labels: {
      priority: 'أفضلية',
      type: 'مشكلة',
      subject: 'مشكلة',
      content: 'نص'
    },

    eventTypes: {
      all: 'الجميع',
      update: 'تحديث النظام',
      activity: 'نشاط',
      message: 'رسالة',
      warning: 'تحذير'
    },

    priority: {
      all: 'الجميع',
      veryLow: 'قليل جدا',
      low: 'قليل',
      medium: 'متوسط',
      high: 'كثير',
      veryHigh: 'كثيرا جدا'
    },

    validators: {
      subject: 'لم يتم إدخال الموضوع بشكل صحيح',
      content: 'لم يتم إدخال المحتوى بشكل صحيح'
    },

    notifications: {
      insert: {
        success: 'تم ارسال الرسالة بنجاح',
        error: 'تم إرسال الرسالة مع وجود خطأ'
      },

      parseError: {
        type: 'النوع غير محدد',
        priority: 'لم يتم تحديد الأولوية'
      }
    },

    list: {
      table: {
        header: {
          rowNumber: 'صف',
          sender: 'مرسل',
          subject: 'مشكلة',
          content: 'محتوى',
          type: 'اكتب',
          priority: 'أفضلية',
          sendDate: 'تاريخ الإرسال'
        }
      }
    },

    insert: {
      title: 'أرسل رسالة جديدة'
    },

    event: {
      labels: {
        understand: 'أدركت'
      }
    }
  },

  // draggable dynamic table
  draggableDynamicTable: {


    filters: 'المرشحات',

    activeFilters: {
      title: 'المرشحات التطبيقية'
    },

    setting: {
      showColumns: 'إظهار الأعمدة'
    },

    notifications: {
      loading: 'تحميل المعلومات ...',
      error: 'تلقي خطأ المعلومات',
      empty: 'لا توجد معلومات'
    },

    actions: {
      print: 'مطبعة',
      download: 'تم الاستلام',
      setting: 'إعدادات الجدول'
    }
  },


  // accessTreeCheckBox
  accessTreeCheckBox: {
    labels: {
      chooseCategory: 'حدد الوصول'
    }
  },


  // custom dialog
  customDialog: {
    confirm: 'تأكيد',
    cancel: 'يلغي'
  },

  // date picker
  datePicker: {
    labels: {
      submit: 'اختيار',
      cancel: 'إلغاء',
      now: 'الآن',
      nextMonth: 'الشهر القادم',
      prevMonth: 'الشهر الماضي'
    }
  },

  //Button
  create: 'يضيف'
}
