export default {
  title: 'Sales',

  proformaInvoices: {
    title: 'Draft'

  },

  invoices: {
    title: 'Sales',

    labels: {
      id: 'Invoice ID',
      user: 'Customer',
      fullName: 'Full Name',
      address: 'Address',
      email: 'Email',
      registryDate: 'Registration Date',
      orderDate: 'Order Date',
      phoneNumber: 'Phone number',
      pre: 'pre',
      status: 'Status',
      gift: 'Gift',
      store: 'Store',
      note: 'Note',
      cooperation: 'Cooperation',
      availableProduct: 'Available Products',
      allProduct: 'All Products',

      productPrice: 'Products price',
      servicePrice: 'Services price',
      tax: 'Vat amount',
      totalDiscount: 'Discount',
      totalVatExc: 'Total (vat EXC)',
      totalPrice: 'Total price',
      totalVatInc: 'Total',
      grandTotal: 'Grand total',
      shipping: 'Shipping',
      required: '(required)',
      totalCount: 'Qty',
      totalVat: 'Total vat',
      total: 'Amount',
      totalGrand: 'Grand total',
      TRN: 'TRN: ',
      vatExc: 'vat Exc',
      vatInc: 'vat Inc',
      vatContainTextBefore: 'includes ',
      vatContainTextAfter: 'VAT amount',
      attachmentLabel: 'Attachment {key}',
      services: {
        name: 'Service name',
        quantity: 'Quantity',
        price: 'Price'
      }
    },

    placeholders: {
      id: 'Automatic'
    },

    prompts: {
      receive: {
        title: 'Receive money from the customer',
        dynamicTitle: 'Receive funds from {name}',

        subject: 'Sales invoice number {id}',

        actions: {
          payment: 'Pay',
          cancel: 'Cancel and view draft'
        }
      },

      confirm: {
        title: 'Create sale invoice confirmation',

        actions: {
          payment: 'Confirm',
          cancel: 'Cancel'
        }
      },

      sendMail: {
        title: 'Send sale invoice to email'
      },

      address: {
        insert: {
          title: '',
          dynamicTitle: 'Add new address for {name}'
        },

        list: {
          title: '',
          dynamicTitle: 'Address List {name}'
        }
      },

      users: {
        list: {
          title: 'User List'
        },
        insert: {
          title: 'Add new user'
        }
      },

      noteDialog: {
        title: 'Customer Invoice Note'
      },

      activeInvoiceExist: {
        actions: {
          confirm: 'Invoice status changed, Save'
        }
      }
    },

    actionTypes: {
      present: 'Retail',
      tel: 'Telephone',
      internet: 'Internet'
    },

    processTypes: {
      proformaInvoices: 'Draft',
      invoices: 'Invoice',
      storeExit: 'Pull out',
      incomplete: 'Incomplete'
    },

    notifications: {
      isExists: {
        message: 'This value is in the list',
        dynamicMessage: 'There are {name} in the list'
      },

      increaseCount: {
        message: 'Number of items added',
        dynamicMessage: 'Number of { names } added'
      },

      productNotFount: 'No product found with this barcode',
      quantity: 'Inventory is not enough!',
      dynamicQuantity: 'There is not enough stock of {name}!',
      activeRefundInvoiceExists: 'For this invoice, return invoice {invoices} are created. First, change the status of these invoices to draft | For this invoice, return invoices {invoices} are created. First, change the status of these invoices to draft',
      lowBalance: 'Draft was registered, but due to lack of customer credit, it is necessary to receive money to complete the invoice!',
      discountIsBigger: 'The discount of {name} is more than the total price of the product!',

      insert: {
        success: 'Invoice registered successfully',
        error: 'Invoice registration was incorrect!'
      },

      edit: {
        success: 'Invoice edited successfully',
        error: 'Invoice editing encountered an error!'
      },

      delete: {
        success: 'Invoice successfully deleted',
        error: 'Deleting the sales invoice was accompanied by an error'
      },

      restore: {
        success: 'Invoice successfully restored',
        error: 'Restore sales invoice was accompanied by an error'
      },

      refreshStatus: {
        success: 'Invoice Status Returned Successfully',
        error: 'Return invoice status has error'
      },

      parseError: {
        user: 'Customer not selected!',
        accountSide: 'Account side not selected!',
        lines: 'No items selected!',
        address: 'The address was not entered correctly!',
        storeroom: 'Warehouse not selected!',
        unitPrice: 'The amount of the goods has not been entered!',
        invoiceNumber: 'This invoice number is already selected!',
        quantity: 'The number of selected products is not valid!',
        discount: 'The entered discount is not valid!',
        taxRate: 'The entered vat is not valid!',
        dynamicDiscount: 'The entered discount in row {index} is not valid!',
        noProductEnter: 'No Product Selected!',
        cooperationId: 'No user selected for cooperation',
        cooperationPrice: 'Cooperation price is not valid'
      }
    },

    confirmations: {
      delete: {
        title: 'Confirmation of deletion of sales invoice',
        body: 'If the sales invoice is deleted, all the documents and delivery note of the warehouse related to the invoice will be deleted. Are you sure? '
      },
      forceDelete: {
        title: 'Confirmation of deletion of sales invoice',
        body: 'If the sales invoice is deleted, the information of invoice deleted and cant restore it. Are you sure? '
      },
      preInvoiceWithoutQuantity: {
        title: 'Pre-sale invoice confirmation',
        body: 'Some of the selected products are out of stock. Are you sure of confirming the invoice as a draft?'
      },
      forceUpdate: {
        title: 'Sale invoice edit confirmation',
        body: 'By edit sale invoice, All documents related to this invoice change. Are you sure of confirming edit sale invoice?'
      }
    },

    list: {
      title: 'Sales List',

      table: {
        header: {
          id: 'Number',
          receivedId: 'Reference',
          creator: 'Creator',
          user: 'Customer',
          province: 'Province',
          country: 'Country',
          date: 'Date',
          totalDiscount: 'Discount',
          totalVat: 'Vat',
          shipping: 'Shipping',
          finalPrice: 'Grand Amt',
          type: 'Type',
          status: 'Status',
          restore: 'Restore',
          delete: 'Delete'
        },

        filters: {
          type: {
            all: 'All',
            present: 'Retail',
            tel: 'Telephone',
            internet: 'Internet'
          },
          status: {
            all: 'All',
            proformaInvoices: 'Draft',
            invoices: 'Invoice',
            storeExit: 'Pull out',
            incomplete: 'Incomplete'
          }
        }
      },

      actionTypes: {
        present: 'Retail',
        tel: 'Telephone',
        internet: 'Internet'
      }
    },

    insert: {
      title: 'Add sales invoice',

      telTypeTitle: 'Add Telephone Invoice',
      presentTypeTitle: 'Add Retail Sales Invoice',

      table: {
        header: {
          rowNumber: 'Row',
          barcode: 'Barcode',
          name: 'Product / Service',
          variant: 'Variable',
          storeroom: 'Store',
          count: 'QTY',
          tax: 'Vat',
          unitTax: 'Unit vat',
          unitPrice: 'Unit',
          totalPrice: 'Total',
          discount: 'Discount',
          unitDiscount: 'Unit Disc.',
          netUnit: 'Net unit',
          subTotal: 'Sub total',
          finalPrice: 'Total',
          delete: 'Delete'
        }
      }
    },

    invoice: {
      title: 'Sale Invoice',
      dynamicTitle: '{type} Sale {pre} Invoice No. {id}',
      dynamicTitleWithoutId: '{type} Sale Invoice',

      labels: {
        payment: 'payment',
        cancel: 'Cancel'
      },

      table: {
        header: {
          rowNumber: 'Row',
          barcode: 'Barcode',
          name: 'Product / Service',
          variant: 'Variable',
          storeroom: 'Store',
          count: 'QTY',
          tax: 'Vat',
          unitTax: 'Unit vat',
          unitPrice: 'Unit',
          totalPrice: 'Total',
          discount: 'Discount',
          gift: 'Gift',
          unitDiscount: 'Unit Disc.',
          netUnit: 'Net unit',
          subTotal: 'Sub total',
          finalPrice: 'Total',
          delete: 'Delete'
        }
      },

      validators: {
        user: 'Supplier not selected!',
        products: 'No product selected!',
        store: 'Warehouse not selected!',
        unitPrice: 'The amount of the goods has not been entered!',
        invoiceNumber: 'This invoice number is already selected!',
        quantity: 'Inventory is not enough!',
        lowPrice: 'Draft was registered, but due to lack of customer credit, you need to receive money to complete the invoice!'
      },

      notifications: {
        complete: {
          success: 'Invoice registered successfully',
          error: 'Invoice registration was incorrect!'
        },

        sendMail: {
          success: 'Invoice send to email successfully',
          error: 'Send invoice to email has error!'
        },

        storeExit: {
          error: 'Sales invoice has not been registered'
        }
      },

      actionTypes: {
        present: 'Retail',
        tel: 'Telephone',
        internet: 'Internet'
      },

      actions: {
        print: 'Print',
        back: 'Back'
      },

      logs: {
        title: 'Sale invoice Logs',
      },

      print: {
        title: 'Sale invoice',
        preInvoice: 'Draft ',
        taxInvoice: 'Tax invoice ',

        labels: {
          date: 'Date',
          documentNumber: 'No',
          note: 'Note',
          TRN: 'TRN',
          printNumber: 'No',
          shipTo: 'To',
          shipFrom: 'From',
          phone: 'Phone',
          address: 'Address',
          total: 'Total',
          beforeTax: 'Before vat',
          tax: 'Vat',
          totalTax: 'Total vat',
          afterTax: 'After vat',
          totalDiscount: 'Total discount',
          totalPrice: 'Subtotal',
          finalPrice: 'Grand total',
          shipping: 'Shipping',
          bankInfo: 'Bank Info',
          accountName: 'Account Name',
          bankName: 'Bank Name',
          iban: 'IBAN',
          cardNumber: 'Card number',
          swiftCode: 'Swift code',
          vatExc: 'vat Exc',
          vatInc: 'vat Inc'
        },

        table: {
          header: {
            row: 'No',
            barcode: 'Barcode',
            description: 'Item Description',
            count: 'Qty',
            tax: 'Vat',
            unitPrice: 'Unit',
            unitPriceVatExc: 'Unit (vat Exc)',
            netPrice: 'Net unit',
            totalPrice: 'Total price',
            discount: 'Disc.',
            unitDiscount: 'Unit Disc.',
            finalPrice: 'Net Amt',
            subTotal: 'Sub total'
          }
        }
      },
    },

    edit: {
      title: 'Edit Sale Invoice',
      dynamicTitle: 'Edit {type} Sale Invoice',

      labels: {
        first: 'first',
        second: 'second'
      },

      table: {
        header: {
          rowNumber: 'Row',
          barcode: 'Barcode',
          name: 'Product',
          variant: 'Variable',
          store: 'Store',
          count: 'Qty',
          unitPrice: 'Price (vat Exc)',
          totalPrice: 'Amount',
          discount: 'Discount',
          finalPrice: 'Total (vat Inc)',
          delete: 'Delete'
        }
      },

      actionTypes: {
        present: 'Retail',
        tel: 'Telephone',
        internet: 'Internet'
      }
    },

    trash: {
      title: 'Sale invoice trash list'
    },

    suggest: {
      title: 'Suggest sale',

      table: {
        header: {
          row: 'Row',
          image: 'Image',
          barcode: 'Barcode',
          code: 'Code',
          category: 'Category',
          name: 'Title',
          variant: 'Variant',
          score: 'Point',
          totalSold: 'Total sold',
          saleInventory: 'Sale inventory'
        }
      }
    },

    refreshStatus: {
      title: 'Return the sales invoice status',

      description: 'Keep in mind that if the invoice status is returned, all documents and delivery note related to each status will be deleted',

      confirm: 'Confirm',
      cancel: 'Cancel'
    },

    activeInvoiceExist: {
      title: 'In order to apply the changes, the status of the following invoices must first be changed to draft',

      table: {
        header: {
          id: 'Invoice number',
          type: 'Type',
          user: 'Customer',
          date: 'Date',
          status: 'Status'
        }
      },

      invoiceTypes: {
        1: 'Sale',
        2: 'Purchase',
        3: 'Sale Refund',
        4: 'Purchase Refund'
      }
    }
  },

  returnInvoices: {
    title: 'Order Refunds',

    labels: {
      user: 'Customer',
      id: 'Invoice ID',
      sourceInvoice: 'Source',
      date: 'Registration date',
      status: 'Status'
    },

    placeholders: {
      id: 'Automatic'
    },

    status: {
      all: 'All',
      preInvoice: 'Draft',
      invoice: 'Invoice',
      storeReceived: 'Pull in',
      incomplete: 'Incomplete'
    },

    actionTypes: {
      present: 'Retail',
      tel: 'Telephone',
      internet: 'Internet'
    },

    notifications: {
      document: {
        success: 'Invoice document successfully registered',
        error: 'The registration of the invoice document was accompanied by an error',
        isExist: 'Invoice document registered'
      },

      insert: {
        success: 'Sales refund invoice successfully registered',
        error: 'Registration of sales refund invoice was accompanied by an error!'
      },

      edit: {
        success: 'Sale refund invoice successfully edited',
        error: 'Editing the refund sale invoice was accompanied by an error!'
      },

      delete: {
        success: 'Sale invoice successfully deleted',
        error: 'Delete sale invoice has error'
      },

      restore: {
        success: 'Invoice successfully restored',
        error: 'Restore  refund sale invoice was accompanied by an error'
      },

      refreshStatus: {
        success: 'Invoice status returned successfully',
        error: 'Invert invoice status returned failed'
      },

      parseError: {
        user: 'Supplier not selected!',
        lines: 'No items selected!',
        store: 'Warehouse not selected!',
        unitPrice: 'The amount of the goods has not been entered!',
        invoiceNumber: 'This invoice number is already selected!',
        count: 'The number of selected products is not valid!',
        quantity: 'Inventory is not enough!',
        dynamicQuantity: '{name} set refund and can\'t refund it again!'
      }
    },

    confirmations: {
      delete: {
        title: 'Confirmation delete the refunded sales invoice',
        body: 'In case of deleting the refunded sales invoice, all documents and warehouse transfers related to the invoice will be deleted. are you sure?'
      },
      forceDelete: {
        title: 'Confirmation of deletion refund sale invoice',
        body: 'If the refund sales invoice is deleted, the information of invoice deleted and cant restore it. Are you sure? '
      },
      forceUpdate: {
        title: 'Refund sale invoice edit confirmation',
        body: 'By edit refund sale invoice, All documents related to this invoice change. Are you sure of confirming edit return sale invoice?'
      }
    },

    list: {
      title: 'Order Refunds',

      table: {
        header: {
          id: 'Number',
          receivedId: 'Reference',
          creator: 'Creator',
          user: 'Customer',
          country: 'Country',
          province: 'Province',
          date: 'Date',
          totalPrice: 'Amount',
          totalDiscount: 'Discount',
          totalVat: 'Vat',
          shipping: 'Shipping',
          finalPrice: 'Net Amt',
          type: 'Type',
          status: 'Status',
          restore: 'Restore',
          delete: 'Delete'
        },

        filters: {
          type: {
            all: 'All',
            present: 'Retail',
            tel: 'Telephone',
            internet: 'Internet'
          }
        }
      }
    },

    insert: {
      title: 'Create New Order Refunds',

      typeTitle: {
        present: 'Add retail sales refund invoice',
        tel: 'Add telephone sales refund invoice',
        internet: 'Add internet sales refund invoice'
      },

      table: {
        header: {
          rowNumber: 'Row',
          barcode: 'Barcode',
          name: 'Product',
          variant: 'Variable',
          count: 'Qty',
          tax: 'Vat',
          unitPrice: 'Unit',
          totalPrice: 'Amount',
          discount: 'Total discount',
          unitDiscount: 'Unit Disc.',
          netUnit: 'Net unit',
          subTotal: 'Sub total',
          store: 'Store',
          delete: 'Delete'
        }
      }
    },

    invoice: {
      title: 'Sale Refund Invoice',
      dynamicTitle: '{type} Sale Refund {pre} Invoice No. {id}',
      dynamicTitleWithoutId: '{type} Sale Refund Invoice ',

      table: {
        header: {
          rowNumber: 'Row',
          code: 'Barcode',
          name: 'Product',
          variant: 'Variable',
          tax: 'Vat',
          discount: 'Total discount',
          unitDiscount: 'Unit Disc.',
          store: 'Store',
          count: 'Qty',
          unitPrice: 'Unit',
          netUnit: 'Net unit',
          subTotal: 'Sub total',
          totalPrice: 'Amount'
        }
      },

      logs: {
        title: 'Sale Refund Invoice Logs',
      },

      print: {
        title: 'Sale refund invoice',
        preInvoice: 'Draft refund',
        taxInvoice: 'Tax refund invoice ',
      }
    },

    edit: {
      title: 'Edit sale Refund invoice',

      typeTitle: {
        present: 'Edit retail sale refund invoice',
        tel: 'Edit telephone sale refund invoice',
        internet: 'Edit internet sale refund invoice'
      },

      table: {
        header: {
          rowNumber: 'Row',
          barcode: 'Barcode',
          name: 'Product',
          variant: 'Variable',
          count: 'Qty',
          tax: 'Vat',
          unitPrice: 'Unit',
          totalPrice: 'Amount',
          discount: 'Total discount',
          unitDiscount: 'Unit Disc.',
          netUnit: 'Net unit',
          subTotal: 'Sub total',
          store: 'Store',
          delete: 'Delete'
        }
      }
    },

    trash: {
      title: 'Return sale invoice trash'
    },

    refreshStatus: {
      title: 'Return sales invoice status',

      labels: {
        description: 'Note that if the invoice status is returned, all documents and delivery note related to each status will be deleted',
        accept: 'Confirm',
        cancel: 'Cancel'
      }
    }
  },

  serviceInvoices: {
    title: 'Service invoices',

    labels: {
      id: 'Id',
      user: 'Customer',
      address: 'Address',
      registryDate: 'Registry Date',
      status: 'Status',
      store: 'Warehouse'
    },

    placeholders: {
      id: 'Automatic'
    },

    prompts: {
      receive: {
        title: 'Receive money from the customer',
        dynamicTitle: 'Receive funds from {name}',

        subject: 'Services sale invoice number {id}',

        actions: {
          payment: 'Pay',
          cancel: 'Cancel and view draft'
        }
      },

      address: {
        insert: {
          title: '',
          dynamicTitle: 'Add new address for {name}'
        },

        list: {
          title: '',
          dynamicTitle: 'Address List {name}'
        }
      },

      users: {
        list: {
          title: 'User List'
        },
        insert: {
          title: 'Add new user'
        }
      }
    },

    actionTypes: {
      present: 'Retail service invoice',
      tel: 'Telephone service invoice',
      internet: 'Internet service invoice'
    },

    processTypes: {
      proformaInvoices: 'service draft',
      invoices: 'service invoice',
      storeExit: 'Exit Warehouse'
    },

    notifications: {
      isExists: {
        message: 'This value is in the list',
        dynamicMessage: 'There are {name} in the list'
      },

      increaseCount: {
        message: 'Number of items added',
        dynamicMessage: 'Number of { names } added'
      },

      productNotFount: 'No product found with this barcode',
      quantity: 'Inventory is not enough!',
      dynamicQuantity: 'There is not enough stock of {name}!',
      lowBalance: 'Draft was registered, but due to lack of customer credit, it is necessary to receive money to complete the invoice!',
      discountIsBigger: 'The discount of {name} is more than the total price of the service!',

      insert: {
        success: 'Invoice registered successfully',
        error: 'Invoice registration was incorrect!'
      },

      edit: {
        success: 'Invoice edited successfully',
        error: 'Invoice editing encountered an error!'
      },

      delete: {
        success: 'Invoice successfully deleted',
        error: 'Deleting the sales invoice was accompanied by an error'
      },

      refreshStatus: {
        success: 'Invoice Status Returned Successfully',
        error: 'Return invoice status has error'
      },


      parseError: {
        user: 'Supplier not selected!',
        accountSide: 'Account side not selected!',
        lines: 'No items selected!',
        address: 'The address was not entered correctly!',
        storeroom: 'Warehouse not selected!',
        unitPrice: 'The amount of the goods has not been entered!',
        invoiceNumber: 'This invoice number is already selected!',
        quantity: 'The number of selected products is not valid!',
        discount: 'The entered discount is not valid!'
      }
    },

    confirmations: {
      delete: {
        title: 'Confirmation of deletion of service invoice',
        body: 'If the service invoice is deleted, all the documents and delivery note of the warehouse related to the invoice will be deleted. Are you sure? '
      }
    },

    list: {
      title: 'Service invoices',

      table: {
        header: {
          id: 'Invoice number',
          receivedId: 'Reference invoice number',
          creator: 'Creator',
          user: 'Customer',
          date: 'Date',
          finalPrice: 'Net Amt',
          type: 'Type',
          status: 'Status'
        },

        filters: {
          type: {
            all: 'All',
            present: 'Presence Sales',
            tel: 'Telephone sales',
            internet: 'Internet sales'
          },
          status: {
            all: 'All',
            proformaInvoices: 'service draft',
            invoices: 'service invoice',
            storeExit: 'Exit Warehouse'
          }
        }
      },

      actionTypes: {
        present: 'Presence service',
        tel: 'Telephone service',
        internet: 'Internet service'
      }
    },

    insert: {
      title: 'Add service invoice',

      telTypeTitle: 'Add Telephone Invoice',
      presentTypeTitle: 'Add Retail Service Invoice',

      table: {
        header: {
          rowNumber: 'Row',
          barcode: 'Barcode',
          name: 'Service',
          variant: 'Variable',
          count: 'Qty',
          unitPrice: 'Price (vat Exc)',
          totalPrice: 'Amount',
          discount: 'Total discount',
          finalPrice: 'Net Amt',
          storeroom: 'Store',
          delete: 'Delete'
        }
      }
    },

    invoice: {
      title: 'Service invoice',
      dynamicTitle: 'Service Invoice {type} No. {id}',
      dynamicTitleWithoutId: 'Service Invoice {type}',

      labels: {
        payment: 'Pay',
        cancel: 'Cancel'
      },

      table: {
        header: {
          rowNumber: 'Row',
          code: 'Barcode',
          name: 'Service',
          count: 'Quantity',
          unitPrice: 'Price (vat Exc)',
          totalPrice: 'Amount',
          discount: 'Discount',
          finalPrice: 'Net Amt'
        }
      },

      validators: {
        user: 'Supplier not selected!',
        products: 'No Service selected!',
        store: 'Warehouse not selected!',
        unitPrice: 'The amount of the service has not been entered!',
        invoiceNumber: 'This invoice number is already selected!',
        quantity: 'Inventory is not enough!',
        lowPrice: 'Draft was registered, but due to lack of customer credit, you need to receive money to complete the invoice!'
      },

      notifications: {
        complete: {
          success: 'Invoice registered successfully',
          error: 'Invoice registration was incorrect!'
        },

        storeExit: {
          error: 'Service invoice has not been registered'
        }
      },

      actionTypes: {
        present: 'Retail',
        tel: 'Telephone',
        internet: 'Internet'
      }
    },

    edit: {
      title: 'Edit Service Invoice',
      dynamicTitle: 'Edit Service Invoice {type}',

      labels: {
        first: 'First',
        second: 'Second'
      },

      table: {
        header: {
          rowNumber: 'Row',
          barcode: 'Barcode',
          name: 'Service',
          variant: 'Variable',
          store: 'Store',
          count: 'Qty',
          unitPrice: 'Price (vat Exc)',
          totalPrice: 'Amount',
          discount: 'Discount',
          finalPrice: 'Net Amt',
          delete: 'Delete'
        }
      },

      actionTypes: {
        present: 'Retail',
        tel: 'Telephone',
        internet: 'Internet'
      }
    },

    refreshStatus: {
      title: 'Return the Service invoice status',

      description: 'Keep in mind that if the invoice status is returned, all documents and delivery note related to each status will be deleted',

      statusTypes: {
        preInvoice: 'Service draft',
        invoice: 'Service invoice'
      },

      confirm: 'Confirm',
      cancel: 'Cancel'
    }
  },

  groups: {
    title: 'Sales Groups',

    list: {
      title: 'List of sales groups'
    },

    insert: {
      title: 'Add new sales group'
    },

    edit: {
      title: 'Edit Sales Group'
    }
  },

  pricing: {
    title: 'Pricing',

    table: {

      header: {
        row: 'Row',
        code: 'Code',
        name: 'Name',
        dynamic: 'Variable',
        amount: 'Mission',
        date: 'Registration date',
        action: 'Operation'
      },

      content: {

        button: {
          set: 'Register'
        }
      }
    }
  },

  discount: {
    title: 'Sales Discounts'

  },

  actions: {
    insert: 'Add',
    edit: 'edit',
    filters: 'Filters'
  }
}
