export default {
  title: 'Users',

  labels: {
    user: 'person'
  },

  gender: {
    man: 'Mr.',
    woman: 'Mrs.',
    company: 'company'
  },

  types: {
    man: 'Mr.',
    woman: 'Mrs.',
    company: 'company'
  },

  characters: {
    shareholder: 'shareholder',
    employee: 'employee',
    intermediary: 'intermediary',
    supplier: 'Supplier',
    customer: 'client'
  },

  notifications: {
    parseError: {
      phoneNumber: 'Phone number is exist in system'
    }
  },

  list: {
    title: 'Users',

    active: 'Active',
    customer: 'Client',
    presence: 'In shop',

    table: {
      header: {
        row: 'Row',
        select: 'Select',
        registryDate: 'Created Date',
        phoneNumber: 'Mobile',
        gender: 'Gender',
        name: 'Name',
        score: 'Point',
        group: 'Group',
        company: 'Company',
        accessGroup: 'Access',
        maxDebt: 'Credit',
        balance: 'Balance',
        address: 'Address',
        access: 'Access',
        status: 'Status',
        shops: 'Ecommerce',
        deleteAction: 'Delete'
      }
    }
  },

  trash: {
    title: 'Removed users',

    table: {
      header: {
        restoreAction: 'Restore',
        deleteAction: 'Delete permanently',
        address: 'Addresses',
        maxDebt: 'Credit',
        balance: 'Balance',
        phoneNumber: 'Mobile number',
        name: 'Name and surname',
        registryDate: 'Membership Date',
        row: 'Row'
      }
    },

    confirmations: {
      deleteUser: {
        title: 'Confirm user deletion',
        body: 'Are you sure you want to delete {userName} permanently?'
      }
    }
  },

  select: {
    title: 'Users',

    table: {
      header: {
        address: 'Addresses',
        maxDebt: 'Credit',
        balance: 'Balance',
        accessGroup: 'User Group',
        phoneNumber: 'Mobile number',
        company: 'Company',
        name: 'Name and surname',
        registryDate: 'Membership Date',
        row: 'Row',
        select: 'Select'
      }
    }
  },

  openingBalance: {
    type: {
      debtor: 'debtors to the company',
      creditor: 'Creditors of the company'
    },

    labels: {
      importData: 'Upload user opening balance file'
    },

    insert: {
      title: 'Add the first course {type}',

      notifications: {
        debtor: {
          success: {
            title: 'Message',
            message: 'Debtors first term successfully registered'
          },

          error: {
            title: 'error',
            message: 'Registration of the first information of the debtor users period was accompanied by Khaza'
          }
        },

        creditor: {
          success: {
            title: 'Message',
            message: 'The first period of creditors successfully registered'
          },

          error: {
            title: 'error',
            message: 'Registration of the first information period of creditor users was associated with Khaza'
          }
        },

        locked: {
          title: 'error',
          message: 'The first is the lock period'
        },

        importData: {
          success: 'User opening balance file successfully uploaded',
          error: 'Upload user opening balance has error'
        },

        isExists: {
          title: 'error',
          message: 'This value is in the list',
          dynamicMessage: 'There are {name} in the list'
        }
      }
    },

    edit: {
      title: 'First Edition {user}',

      labels: {
        price: 'Price',
        delete: 'Delete first course'
      },

      confirmations: {
        delete: {
          title: 'Delete Confirmation',
          body: 'Are you sure you want to delete the first person in the course?'
        }
      },

      notifications: {
        debtor: {
          update: {
            success: {
              title: 'Message',
              message: 'The first period of the debtor in question has been successfully updated'
            },

            error: {
              title: 'error',
              message: 'The first update of the debtors course was accompanied by an error'
            }
          },

          delete: {
            success: {
              title: 'Message',
              message: 'The first period of the desired debtor has been successfully deleted'
            },

            error: {
              title: 'error',
              message: 'The first deletion of the debtors course was accompanied by an error'
            }
          }
        },

        creditor: {
          update: {
            success: {
              title: 'Message',
              message: 'The first period of the creditor in question has been successfully updated'
            },

            error: {
              title: 'error',
              message: 'The first update of the creditors course was accompanied by an error'
            }
          },

          delete: {
            success: {
              title: 'Message',
              message: 'The first period of the creditor in question has been successfully deleted'
            },

            error: {
              title: 'error',
              message: 'The first deletion of the creditors course was accompanied by an error'
            }
          }
        }
      }
    },

    list: {
      table: {
        header: {
          delete: 'Delete',
          price: 'Amount',
          name: 'Name and surname',
          rowNumber: 'Row'
        }
      }
    }
  },

  insert: {
    title: 'Add user',

    base: 'basic',

    gender: {
      man: 'Mr.',
      woman: 'Mrs.'
    },

    labels: {
      name: 'First Name',
      family: 'Last Name',
      gender: 'Gender',
      companyName: 'Company Name',
      phoneNumber: 'Mobile Number',
      email: 'Email'
    },

    validators: {
      company: 'Company name can not be more than 50 characters',
      name: 'Name can not be more than 30 characters',
      nameIsWrong: 'Name not entered correctly',
      nameCantEmpty: 'Name could not be empty',
      family: 'Last name can not be more than 50 characters',
      familyIsWrong: 'Last name not entered correctly',
      familyCantEmpty: 'Last name could not be empty',
      phoneNumber: 'Mobile Number format is incorrect',
      phoneNumberCantEmpty: 'Enter Mobile Number',
      email: 'Email could not exceed 50 characters',
      emailIsWrong: 'Email format is incorrect',
      requiredFieldIsEmpty: 'Required Fields are Missing',
      duplicatePhoneNumber: 'The Mobile Number entered in the system is available'
    },

    notifications: {
      insert: {
        success: 'Information successfully submitted'
      }
    }
  },

  edit: {
    title: 'Edit Information',

    customer: 'client',

    dataCategory: {
      generalInfo: 'General Information',
      accessLevel: 'access level',
      accessGroup: 'User Group'
    },

    labels: {
      name: 'First Name',
      family: 'Last Name',
      company: 'company',
      phoneNumber: 'Mobile Number',
      email: 'Email',
      deleteUser: 'Delete user',
      deleteCustomer: 'Delete Client'
    },

    validators: {
      inputValuesIsWrong: 'The information entered is incorrect'
    },

    useral: {
      confirmations: {
        delete: {
          title: 'Confirm user deletion',
          body: 'Are you sure you want to delete {name} temporarily?'
        }
      },

      notifications: {
        edit: {
          success: 'Personally updated information',
          error: 'Error updating personal information'
        },
        delete: {
          success: 'The person information was deleted',
          error: 'Deleting personal information was associated with an error'
        }
      }
    },

    woocommerce: {
      customerLogs: 'customer logs',

      description: {
        noLogExist: 'There are no logs for this client'
      },

      confirmations: {
        delete: {
          title: 'Confirmation',
          body: 'Are you sure you want to delete this customers information? If approved, the customer information will also be removed from your website! '
        }
      },

      notifications: {
        edit: {
          success: 'Customer information successfully updated',
          error: 'Error updating customer information'
        },
        delete: {
          success: 'The person information was deleted',
          error: 'Deleting customer information was associated with an error'
        },
        requestSend: {
          success: 'Request sent'
        }
      }
    }
  },

  profile: {
    title: 'Profile',
    dynamicTitle: 'Profile',

    importantData: {
      phoneNumber: 'Phone number',
      balance: 'Balance',
      score: 'Point',
      accessGroup: 'Accessibility',
      company: 'Company'
    },

    setMaxDebtModal: {
      title: 'Determining the maximum debt',
      label: 'Maximum personal debt',

      notifications: {
        success: {
          title: 'Message',
          message: 'Maximum debts successfully updated'
        },

        error: {
          title: 'error',
          message: 'Maximum debts update failed'
        }
      }
    },

    addressesModal: {
      title: 'Addresses'
    },

    transactionsModal: {
      title: 'Turnover'
    },

    actions: {
      call: 'Call',
      whatsApp: 'whatsApp',
      setUserMaxDebt: 'Credit Limit',
      showUserAddresses: 'Address List',
      showUserTransactions: 'Transactions',
      insertSaleInvoice: 'Sale',
      insertPurchaseInvoice: 'Purchase',
      insertTreasuryReceive: 'Receive',
      insertTreasuryPayment: 'Payment',
      insertTreasuryCost: 'Cost Document',
      sendMessage: 'Send Message',
      logs: 'Activity logs'
    }
  },

  transactions: {
    title: 'Personal turnover',
    dynamicTitle: 'turnover',
    documentTitle: 'Personal Turnover Document',

    table: {

      header: {
        docNumber: 'Document Number',
        date: 'Date',
        documentation: 'Documents',
        price: 'Amount',
        balance: 'Inventory'
      }
    },

    types: {
      sale: 'Sale',
      purchase: 'Purchase',
      receive: 'Receive'
    }
  },

  addresses: {
    title: 'Personal Addresses',
    dynamicTitle: 'Addresses',

    notifications: {
      edit: {
        success: 'The address has been edited successfully',
        error: 'Edit address has error'
      },
      delete: {
        success: 'Address deleted successfully',
        error: 'Delete address has error'
      },

      parseError: {
        required: 'Required Fields are Missing'
      }
    },

    insert: {
      title: 'Add new address',

      labels: {
        name: 'Address Title',
        country: 'Country',
        province: 'Province',
        city: 'City',
        address: 'Postal address',
        postalCode: 'postal code',
        receiver: {
          boxTitle: 'recipient information',
          name: 'Recipient',
          lastName: 'Recipient surname',
          phoneNumber: 'Caller ID'
        }
      },

      notifications: {
        incorrectDate: {
          title: 'Message',
          message: 'The information was not entered correctly'
        },

        success: {
          title: 'Message',
          message: 'New address successfully registered for user'
        },

        error: {
          title: 'error',
          message: 'Add users new address failed'
        },

        parseError: {
          required: 'Required Fields are Missing'
        }
      }
    },

    edit: {
      title: 'Edit user address'
    },

    list: {

      notFountError: 'No URLs registered!'
    },

    select: {

      table: {
        header: {
          receiverPhoneNumber: 'Recipient Mobile Number',
          receiverLastName: 'Recipient Last Name',
          receiverName: 'Recipient First Name',
          postalCode: 'Postcode',
          address: 'Address',
          city: 'City',
          province: 'Province',
          name: 'Title',
          select: 'Select'
        }
      }
    }
  },

  logs: {
    title: 'Personal Logs',

    table: {
      header: {
        rowNumber: 'Row',
        user: 'User',
        description: 'Description',
        date: 'Date'
      }
    },

    dialogs: {
      title: 'Descriptions',

      noChange: 'No field change'
    }
  },

  actions: {
    insert: 'Add',
    edit: 'edit',
    save: 'Register',
    delete: 'Delete'
  }
}
