export default {
  title: 'Accounting',

  types: {
    title: 'Chart of Accounts',

    labels: {
      name: 'Header Title'
    },

    accountsLabel: {
      asset: 'Asset',
      current_assets: 'Current',
      cash_and_bank_assets: 'Cash and bank',
      cash: 'Cash',
      bank: 'Bank',
      payment_gateway: 'Payment gateway',
      internet_payment_gateway: 'Internet payment gateway',
      pos: 'POS',
      on_way_inventory: 'On way',
      storeroom_inventory: 'Storeroom inventory',
      waiting_to_send_inventory: 'Waiting to send',
      accounts_receivable: 'Accounts receivable',
      inventory: 'Inventory',
      liability: 'Liability',
      current_liabilities: 'Current',
      accounts_payable: 'Accounts payable',
      user_wallet: 'User wallet',
      payable_tax: 'Payable vat',
      vat: 'Vat',
      taxes: 'Vat',
      service_tax: 'Service',
      sale_tax: 'Sale',
      purchase_tax: 'Purchase',
      equity: 'Equity',
      capital: 'Capital',
      opening_capital: 'Opening',
      purchase: 'Purchase',
      product_purchase: 'Product',
      total_product_purchase: 'Total',
      return_product_purchase: 'Return',
      product_purchase_discount: 'Discount',
      service_purchase: 'Service',
      revenue: 'Revenue',
      other_revenue: 'Other revenue',
      service_revenue: 'Service',
      sale_revenue: 'Sale',
      product_sale_revenue: 'Product',
      total_product_sale_revenue: 'Total',
      return_product_sale_revenue: 'Return',
      service_sale_revenue: 'Service',
      personal_sale_revenue: 'Personal',
      total_personal_sale_revenue: 'Total',
      personal_return_sale_expenses: 'Refunds',
      personal_sale_discounts_expenses: 'Discounts',
      internet_sale_revenue: 'Internet',
      total_internet_sale_revenue: 'Total',
      internet_return_sale_expenses: 'Refunds',
      internet_sale_discounts_expenses: 'Discounts',
      telephone_sale_revenue: 'Telephone',
      total_telephone_sale_revenue: 'Total',
      telephone_return_sale_expenses: 'Refunds',
      telephone_sale_discounts_expenses: 'Discounts',
      expenses: 'Expenses',
      other_expenses: 'Other',
      cancel_service_expenses: 'Cancel service',
      personnel_expenses: 'Personnel',
      payroll_expenses: 'Payroll',
      reward_expenses: 'Reward',
      sale_expenses: 'Sale',
      product_sale_expenses: 'Product',
      product_sale_shipping_expenses: 'Shipping',
      product_sale_shipping_expenses_received: 'Received',
      product_sale_shipping_expenses_paid: 'Paid',
      product_sale_discount_expenses: 'Discount',
      service_sale_expenses: 'Service',
      service_sale_discount_expenses: 'Discount',
      sale_shipping_expenses: 'Shipping',
      purchase_expenses: 'Purchase',
      product_purchase_expenses: 'Product',
      product_purchase_shipping_expenses: 'Shipping',
      purchase_shipping_expenses: 'Shipping',
      gift_sale_expenses: 'Gift',
      sale_cooperation_expenses: 'Cooperation',
      financial_expenses: 'Financial',
      wage_expenses: 'Wage',
      bank_wage_expenses: 'Bank',
      payment_gateway_wage_expenses: 'Payment gateway',
      total_purchase: 'Total',
      return_purchase_expenses: 'Refunds',
      purchase_discounts_expenses: 'Discounts',
      storeroom_expenses: 'Warehouse',
      storeroom_deficit: 'Deficit',
      storeroom_surplus: 'Surplus',
      cost_of_goods_bought: 'Cost of goods bought',
      cost_of_goods_sold: 'Cost of goods sold'
    },

    notifications: {
      insert: {
        success: 'The title was successfully created',
        error: 'Logging new topic information failed'
      },

      edit: {
        success: 'The title was successfully created',
        error: 'Update header information failed'
      },

      delete: {
        success: 'Heading deleted successfully',
        error: 'Deleting a topic has error'
      },

      parseError: {
        parent: 'Parent heading not selected',
        name: 'Heading title not entered'
      }
    },

    confirmations: {
      delete: {
        title: 'Delete headline',
        body: 'Are you sure you want to delete the heading {name}?'
      }
    },

    insert: {
      title: 'Add new topic'
    },

    edit: {
      title: 'Edit Heading'
    }
  },

  openingBalance: {
    title: 'Opening Balance Sheet',

    labels: {
      assets: 'Assets',
      liabilities: 'Liabilities',
      equity: 'Capital'
    },

    accountsLabel: {
      cash_and_bank_assets: 'Cash and bank assets',
      bank: 'Bank',
      cash: 'Cash',
      warehouse_inventory: 'Inventory',
      accounts_receivable: 'Accounts receivable',
      accounts_payable: 'Accounts payable',
      capital: 'Capital'
    },

    notifications: {
      locked: 'is the first period of the lock',
      unLocked: 'The first period is not locked',
      lock: {
        success: 'Balance sheet successfully locked',
        error: 'Balance sheet error encountered'
      },
      unlock: {
        success: 'Balance sheet successfully opened',
        error: 'Balance sheet opening encountered an error'
      }
    },

    confirmations: {
      unlock: {
        title: 'Confirmation of opening balance',
        body: 'Are you sure you want to open the opening level?'
      }
    }
  },

  documents: {
    title: 'Documents',

    notifications: {
      delete: {
        success: 'Document information successfully deleted',
        error: 'Deleting document information successfully deleted'
      }
    },

    document: {
      title: 'Document',
      dynamicTitle: 'Document No. {id}',

      table: {
        header: {
          rowNumber: 'Row',
          description: 'Description',
          debt: 'Debtor',
          credit: 'Creditor'
        }
      }
    },

    print: {
      cost: {
        costReceipt: 'Receipt of expenses',
        date: 'Date:',
        docNumber: 'Document number:',
        nameAndSignature: 'Name and signature',
        receiver: 'Receiver',
        payer: 'Payer',
        confirmer: 'Endorser',
        print: 'Print',
        back: 'Back',
        bank: 'Bank',
        cashBox: 'Cash register',
        paymentGateway: 'Payment gateway',

        description: {
          confirm: 'This is confirmed',
          price: 'price (in number): ',
          toText: '(in words) : ',
          account: 'To the account ',
          inDate: 'in date ',
          description: 'for ',
          payer: 'by ',
          payed: 'Was paid'
        }
      },

      receive: {
        receiveReceipt: 'The receipt of funds',
        date: 'Date:',
        docNumber: 'Document number:',
        nameAndSignature: 'Name and signature',
        receiver: 'Receiver',
        payer: 'Payer',
        confirmer: 'Endorser',
        print: 'Print',
        back: 'Back',
        bank: 'Bank',
        cashBox: 'Cash register',
        paymentGateway: 'Payment gateway',
        transactionId: 'Transaction number:',
        paymentConfirm: 'Payment confirmation:',

        description: {
          confirm: 'This is confirmed',
          price: 'price (in number): ',
          toText: '(in words): ',
          payee: 'from mr./ms. ',
          inDate: 'in date ',
          description: 'for ',
          payer: 'by ',
          payed: 'received'
        }
      },

      payment: {
        paymentReceipt: 'Receipt of payment',
        date: 'Date:',
        docNumber: 'Document number:',
        nameAndSignature: 'Name and signature',
        receiver: 'Receiver',
        payer: 'Payer',
        confirmer: 'Endorser',
        print: 'Print',
        back: 'Back',
        bank: 'Bank',
        cashBox: 'Cash register',
        paymentGateway: 'Payment gateway',

        description: {
          confirm: 'This is confirmed',
          price: 'price (in number): ',
          toText: '(in words): ',
          payee: 'to mr./ms. ',
          inDate: 'in date ',
          description: 'for ',
          payer: 'by ',
          payed: 'Was paid'
        }
      },

      internalTransfer: {
        internalTransferReceipt: 'Treasury transfer receipt',
        date: 'Date:',
        docNumber: 'Document number:',
        nameAndSignature: 'Name and signature',
        receiver: 'Receiver',
        payer: 'Payer',
        confirmer: 'Endorser',
        print: 'Print',
        back: 'Back',
        bank: 'Bank',
        cashBox: 'Cash register',
        paymentGateway: 'Payment gateway',

        description: {
          confirm: 'This is confirmed',
          price: 'price (in number): ',
          toText: '(in words): ',
          inDate: 'in date ',
          description: 'for ',
          payer: 'by ',
          payee: 'to ',
          payed: 'Was paid'
        }
      }
    },

    list: {
      title: 'Documents',

      table: {
        header: {
          id: 'Document number',
          createdAt: 'Date',
          creator: 'Creator',
          description: 'Documents',
          type: 'Type'
        },

        filters: {
          type: {
            all: 'All'
          }
        }
      },

      confirmation: {
        deleteDocument: {
          title: 'Confirm Delete Document',
          body: 'Are you sure you want to delete this document completely?'
        }
      },

      types: {
        manual: 'Manual',
        receive: 'Receive',
        payment: 'Payment',
        withdrawal: 'Withdrawal',
        internal_fund_transfer: 'Transfer',
        revenue: 'Revenue',
        cost: 'Cost',
        beginning_inventory: 'Beginning Inventory',
        payroll: 'Payroll',
        purchase: 'Purchase',
        return_purchase: 'Return Purchase',
        sale: 'Sale',
        return_sale: 'Return Sale',
        warehouse_receipt: 'Pull in',
        warehouse_requisition: 'Pull out',
        inventory_transfer: 'Inventory Transfer',
        inventory_deficit: 'Warehouse deficit',
        storeroom_surplus: 'Warehouse surplus',
        sale_cooperation: 'Sale cooperation'
      }
    },

    insert: {
      title: 'Add Document',
      dynamicTitle: 'Add Receipt / Payment Document',

      labels: {
        documentType: 'Document type',
        receive: 'Receive',
        payment: 'Payment',
        paymentTo: 'Payment to',
        receiveFrom: 'Receive from',
        withdrawalFrom: 'Withdrawal from',
        depositTo: 'Deposit to',
        price: 'Amount',
        cashBox: 'Cashier',
        bank: 'Bank',
        depositType: 'Deposit type',
        choose: 'Choose',
        pos: 'POS',
        cardToCard: 'Card to Card',
        shaba: 'IBAN'
      },

      notifications: {

        receive: {
          insert: {
            success: 'Receipt document created successfully',
            error: 'Creating a receive document has error'
          }
        },

        payment: {
          insert: {
            success: 'Payment document created successfully',
            error: 'Creating a payment document has error'
          }
        },

        parseError: {
          user: 'User not selected',
          cashBox: 'Cash is not selected',
          bank: 'Bank not selected',
          price: 'Amount not entered',
          depositTo: 'Deposit to bank or fund is not specified',
          depositType: 'Deposit type not specified',
          withdrawalFrom: 'Withdrawal from bank or fund not specified'
        }
      }
    }
  }
}
